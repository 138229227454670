import React from "react";
import {withLeaflet} from "react-leaflet";
import {ReactLeafletSearch} from "react-leaflet-search";
import {useTranslation} from "react-i18next";

class SearchBarProps {
    usingSearchBar: boolean = true
}


export const SearchBar = (props: SearchBarProps) => {
    const {t} = useTranslation("mapDetails")
    if (props.usingSearchBar) {
        const ReactLeafletSearchComponent = withLeaflet(ReactLeafletSearch);
        return <ReactLeafletSearchComponent zoom={15} showMarker={false} showPopup={false}
                                            inputPlaceholder={t("search")}
                                            position="topright" closeResultsOnClick={true} openSearchOnLoad={true}/>
    }
    return null
}