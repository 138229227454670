import {State} from "../../types/State";
import {
    CreateMapAction,
    DeleteMapAction,
    MapAction,
    ReadMapAction,
    ReadMapsAction,
    UpdateMapAction
} from "../../actions/maps/MapActions";
import {CREATE_MAP, DELETE_MAP, READ_MAP, READ_MAPS, UPDATE_MAP} from "../../events/MapEvents";

export const mapReducer = (state: State, action: MapAction): State => {
    switch (action.type) {
        case CREATE_MAP:
            const newMapAction = action as CreateMapAction
            return {
                ...state,
                maps: [...state.maps, newMapAction.map],
                selectedMap: undefined,
                error: ''
            };
        case READ_MAPS:
            const readMapsAction = action as ReadMapsAction
            return {
                ...state,
                maps: readMapsAction.maps,
                selectedMap: undefined,
                error: ''
            };
        case READ_MAP:
            const readMapAction = action as ReadMapAction
            return {
                ...state,
                selectedMap: readMapAction.map,
                error: ''
            };
        case UPDATE_MAP:
            const updateMapAction = action as UpdateMapAction
            const updatedIndex = state.maps.findIndex((map) => map.id === updateMapAction.map.id)
            if (updatedIndex >= 0) {
                state.maps[updatedIndex] = updateMapAction.map
            }
            return {
                ...state,
                selectedMap: undefined,
                maps: state.maps,
                error: ''
            };
        case DELETE_MAP:
            const deleteMapAction = action as DeleteMapAction
            return {
                ...state,
                selectedMap: undefined,
                maps: state.maps.filter((map) => map.id !== deleteMapAction.map.id),
                error: ''
            };
        default:
            throw new Error('Unexpected action');
    }
}