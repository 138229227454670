import {Config} from "../config"

const conf = Config()
export default {
    apiGateway: {
        REGION: conf.region,
        URL: conf.apiUrl
    },
    cognito: {
        REGION: conf.region,
        USER_POOL_ID: conf.userPoolId,
        APP_CLIENT_ID: conf.appClientId
        // IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
    }
};