import React, {useState} from "react";
import {useAppContext} from "./ContextHook";
import {createPointInMap, deletePoint, errors, getPointsInMap, updatePoint} from '../api/MapDetailsApi'
import {EstuveMap} from "../types/EstuveMap";
import {EstuvePoint} from "../types/EstuvePoint";
import {
    CreatePointAction,
    DeletePointAction,
    ReadPointsAction,
    UpdatePointAction
} from "../actions/points/PointActions";
import {ApiErrorAction} from "../actions/api/ApiActions";

export const useGetPointsInMap = (map: EstuveMap): EstuvePoint[] => {
    const {state, dispatch} = useAppContext();
    React.useEffect(() => {
        if (map)
            getPointsInMap(map)
                .then(points => dispatch(new ReadPointsAction(points)))
                .catch(err => dispatch(new ApiErrorAction(errors.CANNOT_READ_POINTS)))
        // eslint-disable-next-line
    }, [map]);
    const {pointsInMap} = state;
    return pointsInMap;
}

export const useCreatePoint = (map: EstuveMap) => {

    const [point, setPoint] = useState<EstuvePoint | undefined>(undefined)
    const {dispatch} = useAppContext()

    React.useEffect(() => {

        const createPointAndDispatch = (map: EstuveMap, point: EstuvePoint) => {
            createPointInMap(map, point)
                .then(pointCreated => dispatch(new CreatePointAction(pointCreated)))
                .catch(err => dispatch(new ApiErrorAction(errors.CANNOT_CREATE_POINT)))
        }
        if (point) {
            createPointAndDispatch(map, point)
        }
        return () => {
        }; // eslint-disable-next-line
    }, [map, point]);

    return {createPoint: (point: EstuvePoint) => setPoint(point)}
}

export const useUpdatePoint = (map: EstuveMap) => {
    const [point, setPoint] = useState<EstuvePoint | undefined>(undefined)
    const {dispatch} = useAppContext()

    React.useEffect(() => {

        const updatePointAndDispatch = async (point: EstuvePoint, map: EstuveMap) => {
            updatePoint(map, point)
                .then(pointUpdated => dispatch(new UpdatePointAction(pointUpdated)))
                .catch(err => dispatch(new ApiErrorAction(errors.CANNOT_UPDATE_POINT)))
        }

        if (point) {
            updatePointAndDispatch(point, map)
        }
        return () => {
        };
        // eslint-disable-next-line
    }, [map, point]);

    return {updatePoint: (point: EstuvePoint) => setPoint(point)}
}

export const useDeletePoint = (map: EstuveMap, deleteDoneCallback?: Function) => {

    const [point, setPoint] = useState<EstuvePoint | undefined>(undefined)
    const {dispatch} = useAppContext()

    React.useEffect(() => {

        const deletePointAndDispatch = (point: EstuvePoint, map: EstuveMap) => {
            deletePoint(map, point)
                .then(result => {
                    dispatch(result ?
                        new DeletePointAction(point) :
                        new ApiErrorAction(errors.CANNOT_DELETE_POINT))
                    if (deleteDoneCallback)
                        deleteDoneCallback()
                })
                .catch(err => {
                    dispatch(new ApiErrorAction(errors.CANNOT_DELETE_POINT))
                    if (deleteDoneCallback)
                        deleteDoneCallback()
                })
        }
        if (point) {
            deletePointAndDispatch(point, map);
        }
        return () => {
        };

// eslint-disable-next-line
    }, [point]);


    return {deletePoint: (point: EstuvePoint) => setPoint(point)}
}