import moment from "moment"

export class EstuvePoint {
    id: string
    latitude: number
    longitude: number
    description: string | undefined
    date: Date | undefined

    constructor(latitude: number, longitude: number) {
        this.latitude = latitude
        this.longitude = longitude
        this.id = ""
    }

    static displayLatitude(point: EstuvePoint) {
        return point.latitude.toFixed(5)
    }

    static displayLongitude(point: EstuvePoint) {
        return point.longitude.toFixed(5)
    }

    static displayDate(point: EstuvePoint) {
        return moment(point.date).format("YYYY-MM-DD")
    }
}

export class CreatePointRequest {
    latitude: number
    longitude: number
    description: string | undefined
    date: Date | undefined

    constructor(latitude: number, longitude: number, description: string | undefined, date: Date | undefined) {
        this.latitude = latitude
        this.longitude = longitude
        this.description = description
        this.date = date
    }
}