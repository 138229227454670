import {Action} from "../Action";
import {CREATE_MAP, DELETE_MAP, READ_MAP, READ_MAPS, UPDATE_MAP} from "../../events/MapEvents";
import {EstuveMap} from "../../types/EstuveMap";

export class MapAction extends Action {
}

export class ReadMapsAction extends MapAction {
    maps: EstuveMap[] = [];

    constructor(maps: EstuveMap[]) {
        super(READ_MAPS)
        this.maps = maps
    }
}

export class ReadMapAction extends MapAction {
    map?: EstuveMap

    constructor(map: EstuveMap) {
        super(READ_MAP)
        this.map = map
    }
}

export class CreateMapAction extends MapAction {
    map: EstuveMap;

    constructor(map: EstuveMap) {
        super(CREATE_MAP)
        this.map = map
    }
}

export class DeleteMapAction extends MapAction {
    map: EstuveMap;

    constructor(map: EstuveMap) {
        super(DELETE_MAP)
        this.map = map
    }
}

export class UpdateMapAction extends MapAction {
    map: EstuveMap;

    constructor(map: EstuveMap) {
        super(UPDATE_MAP)
        this.map = map
    }
}