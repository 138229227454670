import {CreateMapRequest, EstuveMap} from "../types/EstuveMap";
import {API} from "aws-amplify";
import {getCurrentSession} from "../auth/AmplifyAuth";
import {authTokenHeader} from "./authHeader";
import {createMapAnalytics, deleteMapAnalytics, editMapAnalytics} from "../analytics/ga";

export const errors = {
    CANNOT_READ_MAPS: "CANNOT_READ_MAPS",
    CANNOT_READ_MAP: "CANNOT_READ_MAP",
    CANNOT_CREATE_MAP: "CANNOT_CREATE_MAP",
    CANNOT_UPDATE_MAP: "CANNOT_UPDATE_MAP",
    CANNOT_DELETE_MAP: "CANNOT_DELETE_MAP",
}

const mapMapper = (responseData: any): EstuveMap => {
    let map = new EstuveMap(responseData.name, responseData.description)
    map.id = responseData.id
    map.numberOfPoints = responseData.number_points
    return map
}


export const getMapById = (mapId: string): Promise<EstuveMap> => {
    return getCurrentSession()
        .then((tokens) => {
            return API.get("estuve", `/maps/${mapId}`, {headers: authTokenHeader(tokens)})
                .then((map: any) => mapMapper(map))
        })
}

export const getMapList = (): Promise<EstuveMap[]> => {
    return getCurrentSession()
        .then((tokens) => {
            return API.get("estuve", "/maps", {headers: authTokenHeader(tokens)})
                .then((maps: any) => {
                    return maps.map((map: any) => mapMapper(map))
                })
        })
}

export const createMap = (mapToCreate: EstuveMap): Promise<EstuveMap> => {
    const mapCreateReq = new CreateMapRequest(mapToCreate.name, mapToCreate.description)
    createMapAnalytics()
    return getCurrentSession()
        .then((tokens) => {
            return API.post("estuve", "/maps",
                {body: mapCreateReq, headers: authTokenHeader(tokens)})
                .then((map: any) => mapMapper(map))
        })
}

export const updateMap = (mapToUpdate: EstuveMap): Promise<EstuveMap> => {
    editMapAnalytics()
    return getCurrentSession()
        .then((tokens) => {
            return API.put("estuve", `/maps/${mapToUpdate.id}`,
                {body: mapToUpdate, headers: authTokenHeader(tokens)})
                .then((map: any) => mapMapper(map))
        })
}

export const deleteMap = (mapToDelete: EstuveMap): Promise<Boolean> => {
    deleteMapAnalytics()
    return getCurrentSession()
        .then((tokens) => {
            return API.del("estuve", `/maps/${mapToDelete.id}`, {
                body: mapToDelete, headers: authTokenHeader(tokens)
            }).then(() => true)
        })
}