import {LatLngBoundsExpression, LatLngTuple} from "leaflet";
import {EstuvePoint} from "../../types/EstuvePoint";

const SW: LatLngTuple = [-90, -180]
const NE: LatLngTuple = [90, 180]
export const ALL_WORLD_BOUNDS: LatLngBoundsExpression = [NE, SW]

export const getBoundsFromPoints = (points: EstuvePoint[]): LatLngBoundsExpression => {

    if (points.length === 0)
        return ALL_WORLD_BOUNDS

    let nex = 0, swx = 0, ney = 0, swy = 0
    points.forEach((point) => {
        if (nex === 0 && swx === 0 && ney === 0 && swy === 0) {
            nex = swx = point.longitude
            ney = swy = point.latitude
        } else {
            if (point.longitude > nex) nex = point.longitude;
            if (point.longitude < swx) swx = point.longitude;
            if (point.latitude > ney) ney = point.latitude;
            if (point.latitude < swy) swy = point.latitude;
        }
    })
    return [[ney, nex], [swy, swx]]
}