import React from 'react';
import {EstuveMap} from "../../types/EstuveMap";
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../i18n";

export class MapElementProps {
    map: EstuveMap
    openEditModal: Function
    openDeleteModal: Function

    constructor(map: EstuveMap, openEditModal: Function, openDeleteModal: Function) {
        this.map = map
        this.openDeleteModal = openDeleteModal
        this.openEditModal = openEditModal
    }
}

export const MapElement = (props: MapElementProps) => {
    const {t} = useTranslation("maps")
    return <div className="col-sm-4 mb-3 mb-md-0 p-1">
        <div className="card">
            <div className="card-body text-center">
                <h5 className="card-title" data-testid="map-name">{props.map.name} </h5>
                <p className="card-text" data-testid="map-desc">{props.map.description}</p>
                <p className="card-text" data-testid="map-num-points">{props.map.numberOfPoints} {t("points")}</p>
                <div className={"map-links"}>
                    <a className="card-link" data-testid="open-link"
                       href={`/${getLanguage()}/maps/${props.map.id}`}>{t("open")}</a>
                    <a className="card-link" href={"#!"}
                       onClick={() => props.openEditModal(props.map)}
                       data-testid="edit-link">{t("edit")}</a>
                    <a className="card-link" href={"#!"}
                       onClick={() => props.openDeleteModal(props.map)}
                       data-testid="delete-link">{t("delete")}</a>
                </div>
            </div>
        </div>
    </div>

}