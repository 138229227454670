import {
    CreatePointAction,
    DeletePointAction,
    PointAction,
    ReadPointsAction,
    UpdatePointAction
} from "../../actions/points/PointActions";
import {State} from "../../types/State";
import {CREATE_POINT, DELETE_POINT, READ_POINTS, UPDATE_POINT} from "../../events/PointEvents";

export const pointReducer = (state: State, action: PointAction): State => {
    switch (action.type) {
        case READ_POINTS:
            const readPointsAction = action as ReadPointsAction
            return {
                ...state,
                pointsInMap: readPointsAction.points,
                error: ''
            };
        case UPDATE_POINT:
            const updatePointAction = action as UpdatePointAction
            const updatedPointIndex = state.pointsInMap.findIndex((point) => point.id === updatePointAction.point.id)
            if (updatedPointIndex >= 0) {
                state.pointsInMap[updatedPointIndex] = updatePointAction.point
            }
            return {
                ...state,
                pointsInMap: state.pointsInMap,
                error: ''
            };
        case CREATE_POINT:
            const newPointAction = action as CreatePointAction
            return {
                ...state,
                pointsInMap: [...state.pointsInMap, newPointAction.point],
                error: ''
            };
        case DELETE_POINT:
            const deletePointAction = action as DeletePointAction
            return {
                ...state,
                pointsInMap: state.pointsInMap.filter((point) => point.id !== deletePointAction.point.id),
                error: ''
            };
        default:
            throw new Error('Unexpected action');
    }
}