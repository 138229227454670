import React from 'react';
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {logIn} from "../../auth/AmplifyAuth";
import {MDBAlert} from "mdbreact";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../hooks/ContextHook";
import {SetUsernameAction} from "../../actions/auth/AuthActions";
import {loginFailAnalytics, loginSuccessfulAnalytics, renderLoginAnalytics} from "../../analytics/ga";

export const Login = () => {
    const history = useHistory()
    const {t} = useTranslation("auth");

    const {register, handleSubmit, errors, setError} = useForm();
    const {dispatch} = useAppContext()

    const onSubmit = async (data: any) => {
        logIn(data.email, data.psw)
            .then((username: string) => {
                dispatch(new SetUsernameAction(username))
                loginSuccessfulAnalytics()
                history.push("/maps")
            })
            .catch((err) => {
                if (err.name === "UserNotConfirmedException") {
                    history.push("/confirm")
                } else {
                    loginFailAnalytics()
                    setError("invalidCredentials", "invalidCredentials")
                }
            })
    };


    const handleRegister = () => {
        history.push("/register")
    }

    const handleForgotPassword = () => {
        history.push("/forgot")
    }

    renderLoginAnalytics()
    return <div className={"fullscreen-with-color"}>
        <div className="centered-card card">
            <h5 className="card-header info-color white-text text-center py-4">
                <strong>{t("signIn")}</strong>
            </h5>
            <div className="card-body px-lg-5 pt-0">
                <form data-testid="login-form" className={"md-form"} onSubmit={handleSubmit(onSubmit)}>
                    <input type="text" name={"email"} className="form-control"
                           data-testid="login-email-input"
                           placeholder={t("email")}
                           ref={register({required: true})}/>
                    {errors.email &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='email-error'>{t("required")}</span></MDBAlert>}
                    <input type="password" name={"psw"} className="form-control"
                           data-testid="login-password-input"
                           placeholder={t("password")}
                           ref={register({required: true})}/>
                    {errors.psw &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='password-error'>{t("required")}</span></MDBAlert>}

                    <div className="d-flex align-items-center justify-content-between">
                        {/*eslint-disable-next-line*/}
                        <div><a data-testid="forgot-passwd"
                                onClick={() => handleForgotPassword()}>{t("forgot-password")}</a></div>
                    </div>

                    {errors.invalidCredentials &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='invalid-login-error'>{t("invalid-credentials")}</span></MDBAlert>}

                    <button className="btn btn-info btn-block my-4 waves-effect z-depth-0"
                            data-testid="login-submit" type="submit">
                        {t("login")}
                    </button>
                    <div className="text-center">
                        <p>{t("not a member")}
                            {/*eslint-disable-next-line*/}
                            <a data-testid="register-btn" onClick={() => handleRegister()}>{t("register")}</a>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </div>
}