import {Action} from "../Action";
import {EstuvePoint} from "../../types/EstuvePoint";
import {CREATE_POINT, DELETE_POINT, READ_POINTS, UPDATE_POINT} from "../../events/PointEvents";

export class PointAction extends Action {
}

export class ReadPointsAction extends PointAction {
    points: EstuvePoint[]

    constructor(points: EstuvePoint[]) {
        super(READ_POINTS)
        this.points = points
    }
}

export class CreatePointAction extends PointAction {
    point: EstuvePoint

    constructor(point: EstuvePoint) {
        super(CREATE_POINT)
        this.point = point
    }
}

export class UpdatePointAction extends PointAction {
    point: EstuvePoint

    constructor(point: EstuvePoint) {
        super(UPDATE_POINT)
        this.point = point
    }
}

export class DeletePointAction extends PointAction {
    point: EstuvePoint

    constructor(point: EstuvePoint) {
        super(DELETE_POINT)
        this.point = point
    }
}