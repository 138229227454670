import React, {Suspense, useReducer} from 'react';
import {reducer} from './reducers/reducer';
import {Provider} from "./providers/provider";
import {Config} from "./config"
import {State} from "./types/State";
import {IsUserLoggedIn} from "./auth/AmplifyAuth";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {getLanguage} from "./i18n";
import {Loading} from "./components/loading/Loading";
import {Redirect, Route, Switch} from 'react-router-dom';
import {Main} from "./components/main/Main";
import {Login} from "./components/auth/Login";
import {Register} from "./components/auth/Register";
import {ConfirmUser} from "./components/auth/Confirm";
import {Maps} from "./components/maps/Maps";
import {ForgotPassword} from "./components/auth/ForgotPassword";
import {MapDetails} from "./components/mapDetails/MapDetails";
import {NotFound} from "./components/notfound/NotFound";
import {AboutPage} from "./components/about/About";
import {FeedbackPage} from "./components/contact/FeedbackPage";
import {ProfilePage} from "./components/profile/Profile";
import {initGa} from "./analytics/ga";

const initialState: State = {
    error: '',
    maps: [],
    pointsInMap: [],
    selectedMap: undefined,
    username: ""
}

const App: React.FC = () => {
    const state = useReducer(reducer, initialState);
    return (
        <>
            <Provider value={state}>
                <AppRouters/>
            </Provider>
        </>
    );
}

const AppRouters = () => {
    const conf = Config()
    IsUserLoggedIn()
    if (conf.useGa) {
        initGa()
    }

    const DocumentHeaders = () => {

        const {t} = useTranslation("seo")
        return <Helmet>
            <meta charSet="utf-8"/>
            <title>{t("title")}</title>
            <meta name="description" content={t("description")}/>
            <meta httpEquiv="content-language" content={getLanguage()}/>
            <link rel="canonical" href={conf.publicUrl}/>
            <link rel="alternate" hrefLang="en" href={conf.publicUrl + "/en"}/>
            <link rel="alternate" hrefLang="es" href={conf.publicUrl + "/es"}/>
            <link rel="alternate" hrefLang="ca" href={conf.publicUrl + "/ca"}/>
        </Helmet>
    }

    return <Suspense fallback={<Loading/>}>
        <Switch>
            <Route exact path="/">
                <DocumentHeaders/>
                <Main/>
            </Route>
            < Route exact path="/login"><Login/></Route>
            <Route exact path="/register"><Register/></Route>
            <Route exact path="/confirm"><ConfirmUser/></Route>
            <Route exact path="/forgot"><ForgotPassword/></Route>
            <Route exact path="/forgot/sent"><ForgotPassword emailSent={true}/></Route>
            <Route exact path="/maps"><Maps/></Route>
            <Route path="/maps/:id" render={(props) => {
                return <MapDetails mapId={props.match.params.id}/>
            }}/>
            <Route exact path="/about"><AboutPage/></Route>
            <Route exact path="/contact"><FeedbackPage/></Route>


            <Route exact path="/(ca|es|en)"><DocumentHeaders/><Main/></Route>
            <Route exact path="/(ca|es|en)/login"><Login/></Route>
            <Route exact path="/(ca|es|en)/register"><Register/></Route>
            <Route exact path="/(ca|es|en)/confirm"><ConfirmUser/></Route>
            <Route exact path="/(ca|es|en)/forgot"><ForgotPassword/></Route>
            <Route exact path="/(ca|es|en)/forgot/sent">
                <ForgotPassword emailSent={true}/>
            </Route>
            <Route exact path="/(ca|es|en)/maps"><Maps/></Route>
            <Route exact path="/(ca|es|en)/maps/:id" render={(props) => {
                return <MapDetails mapId={props.match.params.id}/>
            }}/>
            <Route exact path="/(ca|es|en)/about"><AboutPage/></Route>
            <Route exact path="/(ca|es|en)/contact"><FeedbackPage/></Route>
            <Route exact path="/(ca|es|en)/profile"><ProfilePage/></Route>

            <Route exact path={"/404"}><NotFound/></Route>
            <Route path={"*"}><Redirect to="/404"/></Route>
        </Switch>

    </Suspense>
}

export default App;
