import React from 'react';
import {MapElement} from './MapElement'
import {EditMapForm} from './EditMapForm'
import {DeleteMapForm} from './DeleteMapModal'
import {useModal} from '../../hooks/ModalHook'
import {EstuveMap} from "../../types/EstuveMap";
import {useGetMaps} from "../../hooks/MapListHook";
import "./Maps.css"
import {NavBar} from "../navbar/NavBar";
import {useAppContext} from "../../hooks/ContextHook";
import {ApiErrors} from "../errors/ApiErrors";
import Modal from "react-bootstrap/Modal";

class MapModalProps {
    map: EstuveMap
    isShowing: boolean
    toggle: Function

    constructor(map: EstuveMap, isShowing: boolean, toggle: Function) {
        this.map = map
        this.isShowing = isShowing
        this.toggle = toggle
    }
}

const EditMapModal = (props: MapModalProps) => {
    if (props.map)
        return <Modal show={props.isShowing} onHide={() => props.toggle()} centered>
            <Modal.Body>
                <EditMapForm map={props.map} hide={() => props.toggle()}/>
            </Modal.Body>
        </Modal>

    return null
}

const DeleteMapModal = (props: MapModalProps) => {
    if (props.map)
        return <Modal show={props.isShowing} onHide={() => props.toggle()} centered>
            <Modal.Body>
                <DeleteMapForm map={props.map} hide={() => props.toggle()}/>
            </Modal.Body>
        </Modal>

    return null
}

export const Maps = () => {
    const {modalData: editModalData, toggle: editToggle, setDataAndShow: editSetDataAndShow} = useModal()
    const {modalData: deleteModalData, toggle: deleteToggle, setDataAndShow: deleteSetDataAndShow} = useModal()
    const {state} = useAppContext();

    const maps: EstuveMap[] = useGetMaps()

    const openEditForm = (map: EstuveMap) => {
        editSetDataAndShow(map)
    }

    const openDeleteForm = (map: EstuveMap) => {
        deleteSetDataAndShow(map)
    }


    const mapElements = maps.map((currentMap) => <MapElement key={currentMap.id} map={currentMap}
                                                             openEditModal={openEditForm}
                                                             openDeleteModal={openDeleteForm}/>)
    return <div>
        <NavBar/>
        <div className={"maps-wrapper"}>
            <ApiErrors error={state.error}/>
            <div className="row" data-testid={"maps-container"}>
                {mapElements}
            </div>
            <EditMapModal isShowing={editModalData.isShowing} map={editModalData.data} toggle={editToggle}/>
            <DeleteMapModal isShowing={deleteModalData.isShowing} map={deleteModalData.data} toggle={deleteToggle}/>
        </div>
    </div>
}