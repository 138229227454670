import React from 'react';
import './Main.css'
import {useHistory} from 'react-router-dom';
import {MDBContainer} from "mdbreact";
import {Element} from 'react-scroll'
import {useTranslation} from 'react-i18next';
import {MainNavBar} from "./MainNavBar";
import {renderMainPageAnalytics} from "../../analytics/ga";

export const Main = () => {
    const FeatureSection = () => {
        const {t} = useTranslation("main");
        return <div className={"main-features"}>
            <h2 className="h2-responsive-responsive font-weight-bold my-5">{t("featuresTittle")}</h2>
            <p className="lead  w-responsive mx-auto mb-5">{t("featuresSubTittle1")}.<br/>
                {t("featuresSubTittle2")}.<br/>
                {t("featuresSubTittle3")}.
            </p>
            <div className="row p-5">
                <div className="col-md-3">
                    <div className={"box"}>
                        <i className="fas  fa-map-marked fa-3x red-text"/>
                        <h5 className="font-weight-bold my-4">{t("feature1Tittle")}</h5>
                        <p className="mb-md-0 mb-5">{t("feature1Text")}</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={"box"}>
                        <i className="fas fa-map-marker-alt fa-3x cyan-text"/>
                        <h5 className="font-weight-bold my-4">{t("feature2Tittle")}</h5>
                        <p className="mb-md-0 mb-5">{t("feature2Text")}</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={"box"}>
                        <i className="fas fa-search-location fa-3x orange-text"/>
                        <h5 className="font-weight-bold my-4">{t("feature3Tittle")}</h5>
                        <p className="mb-md-0 mb-5">{t("feature3Text")}.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={"box"}>
                        <i className="fas fa-globe-europe fa-3x green-text"/>
                        <h5 className="font-weight-bold my-4">{t("feature4Tittle")}</h5>
                        <p className="mb-md-0 mb-5">{t("feature4Text")}.</p>
                    </div>
                </div>
            </div>
        </div>
    }

    const MainContent = () => {
        const history = useHistory()
        const {t} = useTranslation("main");
        const toLogin = () => {
            history.push("/login")
        }
        const toRegister = () => {
            history.push("/register")
        }

        return <div className={"main-content"}>
            <div className={"row d-flex justify-content-center"}>
                <div className={"col-md-6"}>
                    <div className={"main-intro"}>
                        <h1 className={"white-text"}><b>{t('bigCentralText')}</b></h1>
                        <h6 className={"white-text"}>{t("smallCentralText")}</h6>
                        <h6 className={"white-text"}>{t("smallCentralText2")}</h6>
                        <div>
                            <button data-testid="login-btn" className="btn btn-primary waves-effect z-depth-0"
                                    onClick={toLogin}>
                                {t("login")}
                            </button>
                            <button className="btn btn-default waves-effect z-depth-0" onClick={toRegister}>
                                {t("register")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const WhyContent = () => {
        const {t} = useTranslation("main");
        return <div className={"why-content"}>
            <h2 className="h2-responsive-responsive font-weight-bold my-5 white-text">{t("who and why")}</h2>
            <div className="row p-5">
                <div className="col-md-3">
                    <div className={"photo"}><img src={"/img/me.jpg"} alt={"Adrian Galera"}/></div>
                    <p className="mb-md-0 mb-5 links">
                        <a target="_blank" rel="noopener noreferrer" href={"http://github.com/adriangalera"}><i
                            className="fab fa-github fa-2x"/></a>
                        <a target="_blank" rel="noopener noreferrer" href={"https://www.linkedin.com/in/adriangalera/"}><i
                            className="fab fa-linkedin fa-2x"/></a>
                        <a target="_blank" rel="noopener noreferrer" href={"mailto:estuve@estuve.eu"}> <i
                            className="far fa-envelope-open fa-2x"/></a>
                    </p>
                </div>
                <div className="col-md-6">
                    <div className={"box white"}>
                        <i className="fas fa-lightbulb fa-3x blue-text"/>
                        <h5 className="font-weight-bold my-4">{t("who and why")}</h5>
                        <p className="mb-md-0 mb-5">{t("me1")}.</p><br/>
                        <p className="mb-md-0 mb-5">{t("me2")}.</p><br/>
                        <p className="mb-md-0 mb-5">{t("me3")}.</p><br/>
                        <p className="mb-md-0 mb-5">{t("me4")}.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={"box white"}>
                        <i className="fas fa-code fa-3x blue-text"/>
                        <h5 className="font-weight-bold my-4">{t("tech")}</h5>
                        <p className="mb-md-0 mb-5">{t("tech1")}</p>
                        <p className="mb-md-0 mb-5"><code>Golang, React, Docker, BDD, Gitlab</code></p>
                        <p className="mb-md-0 mb-5"><code>AWS Lambda, Dynamodb, S3, Cognito, API Gateway, SES</code></p>
                        <br/>
                        <p className="mb-md-0 mb-5">{t("tech2")}<a target={"_blank"} rel="noopener noreferrer"
                                                                   href={"https://www.agalera.eu"}>{t("blog")}</a></p>
                    </div>
                </div>

            </div>
        </div>
    }

    renderMainPageAnalytics()

    return <MDBContainer fluid className={"p-0"}>
        <MainNavBar isInMainPage={true}/>
        <Element name={"main"}>
            <section className={"main-section main-content"}>
                <MainContent/>
            </section>
        </Element>
        <Element name={"features"}>
            <section className={"main-section main-features"}>
                <FeatureSection/>
            </section>
        </Element>
        <Element name={"why"}>
            <section className={"main-section why-content"}>
                <WhyContent/>
            </section>
        </Element>
    </MDBContainer>
}