import React from 'react';
import {useModal} from "../../hooks/ModalHook";
import {MDBNavItem} from "mdbreact";
import {ToggleEnum} from "./NavBar";
import "./CreateMapNavButton.css"
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import {NewMapForm} from "../maps/NewMapForm";

class CreateMapNavButtonProps {
    isMapSelected: boolean
    toggleStatus: ToggleEnum

    constructor(isMapSelected: boolean, toggleStatus: ToggleEnum) {
        this.isMapSelected = isMapSelected;
        this.toggleStatus = toggleStatus;
    }
}

class ModalProps {
    isShowing: boolean
    toggle: Function

    constructor(isShowing: boolean, toggle: Function) {
        this.isShowing = isShowing
        this.toggle = toggle
    }
}

const NewMapModal = (props: ModalProps) => {
    return <Modal show={props.isShowing} onHide={() => props.toggle()} centered>
        <Modal.Body>
            <NewMapForm hide={() => props.toggle()}/>
        </Modal.Body>
    </Modal>
}


export const CreateMapNavButton = (props: CreateMapNavButtonProps) => {
    const {t} = useTranslation("navbar")
    const {modalData: addModalData, toggle: addToggle, setDataAndShow: addSetDataAndShow} = useModal()
    if (props.isMapSelected) return null
    const openAddForm = () => addSetDataAndShow({})
    return <MDBNavItem>
        {props.toggleStatus === ToggleEnum.opened && <span className={"nav-content add-text"}>{t("add map")}</span>}
        <span data-testid="add-new-map-btn" className="btn-floating btn-sm btn-info add-map-btn"
              onClick={() => openAddForm()}>
            <i className="fas fa-plus-circle white-text"/>
        </span>
        <NewMapModal isShowing={addModalData.isShowing} toggle={addToggle}/>
    </MDBNavItem>
}