import React from 'react';
import {useTranslation} from "react-i18next";
import {NavBar} from "../navbar/NavBar";
import "./About.css"
import {renderWhoAnalytics} from "../../analytics/ga";


const About = () => {
    const {t} = useTranslation("main");
    renderWhoAnalytics()
    return <div className="row p-5">
        <div className="col-md-3">
            <div className={"photo-wrapper"}>
                <div className={"photo box"}><img src={"/img/me.jpg"} alt={"Adrian Galera"}/>
                    <div className="mb-md-0 mb-5 links">
                        <a target="_blank" rel="noopener noreferrer" href={"http://github.com/adriangalera"}><i
                            className="fab fa-github fa-2x"/></a>
                        <a target="_blank" rel="noopener noreferrer" href={"https://www.linkedin.com/in/adriangalera/"}><i
                            className="fab fa-linkedin fa-2x"/></a>
                        <a target="_blank" rel="noopener noreferrer" href={"mailto:estuve@estuve.eu"}> <i
                            className="far fa-envelope-open fa-2x"/></a>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-6">
            <div className={"box white"}>
                <i className="fas fa-lightbulb fa-3x blue-text"/>
                <h5 className="font-weight-bold my-4">{t("who and why")}</h5>
                <p className="mb-md-0 mb-5">{t("me1")}.</p><br/>
                <p className="mb-md-0 mb-5">{t("me2")}.</p><br/>
                <p className="mb-md-0 mb-5">{t("me3")}.</p><br/>
                <p className="mb-md-0 mb-5">{t("me4")}.</p>
            </div>
        </div>
        <div className="col-md-3">
            <div className={"box white"}>
                <i className="fas fa-code fa-3x blue-text"/>
                <h5 className="font-weight-bold my-4">{t("tech")}</h5>
                <p className="mb-md-0 mb-5">{t("tech1")}</p>
                <p className="mb-md-0 mb-5"><code>Golang, React, Docker, BDD, Gitlab</code></p>
                <p className="mb-md-0 mb-5"><code>AWS Lambda, Dynamodb, S3, Cognito, API Gateway, SES</code></p>
                <br/>
                <p className="mb-md-0 mb-5">{t("tech2")}<a target={"_blank"} rel="noopener noreferrer"
                                                           href={"https://www.agalera.eu"}>{t("blog")}</a></p>
            </div>
        </div>

    </div>
}

export const AboutPage = () => {
    return <div>
        <NavBar/>
        <div className={"about-wrapper"}>
            <About/>
        </div>
    </div>
}