import React, {Component} from "react";
import {EstuvePoint} from "../../types/EstuvePoint";
import Control from "react-leaflet-control";
import "./PointListControl.css"
import {DomEvent} from "leaflet";
import {MDBBadge, MDBInput} from "mdbreact";
import {openPointListAnalytics} from "../../analytics/ga";


class PointListMapControlProps {
    points: EstuvePoint[]
    goToPoint: (point: EstuvePoint) => void
    t: Function

    constructor(points: EstuvePoint[], goToPoint: (point: EstuvePoint) => void, t: Function) {
        this.points = points;
        this.goToPoint = goToPoint;
        this.t = t;
    }
}

class PointListMapControlState {
    visible: boolean = false
    searchedPoints: EstuvePoint[] = []
}

export class PointListMapControl extends Component {

    container: any
    state: PointListMapControlState
    props: PointListMapControlProps

    constructor(props: PointListMapControlProps) {
        super(props);
        this.props = props
        this.container = null;
        this.state = {
            visible: false,
            searchedPoints: []
        }
    }

    refContainer = (element: any) => {
        this.container = element;
        if (element) {
            DomEvent
                .disableClickPropagation(element)
                .disableScrollPropagation(element);
        }
    }

    toggleVisible = () => {
        this.setState({
            visible: !this.state.visible,
            searchedPoints: this.props.points
        })
    }

    pointControl = (p: EstuvePoint, goToPoint: (point: EstuvePoint) => void) => {
        const point = p as EstuvePoint
        return <div className={"row point-div-link"} key={point.id} onClick={() => goToPoint(point)}>
            <div className="col-md-2">
                <i className="fas fa-map-marker-alt fa-2x cyan-text"/>
            </div>
            <div className="col-md-10 point-wrapper">
                <h5 className="point-list-point-desc">{point.description}</h5>
                <p className={"point-list-point-latlng"}>{EstuvePoint.displayLatitude(point)},{EstuvePoint.displayLongitude(point)}</p>
                {point.date && <p className="point-list-point-date">{EstuvePoint.displayDate(point)}</p>}
            </div>
        </div>
    }

    search = (target: string) => {
        if (target.length === 0) {
            this.setState({
                searchedPoints: this.props.points
            })
        } else {
            const searchResult = this.props.points.filter((point: EstuvePoint) =>
                point.description?.toLocaleLowerCase().includes(target))
            this.setState({
                searchedPoints: searchResult
            })
        }
    }

    SearchBar = () => {
        return <MDBInput label={this.props.t("search points")} icon="search-location"
                         data-testid={"marker-list-search-bar"}
                         onChange={(e: any) => this.search(e.target.value.toLowerCase())}/>
    }

    NumberPoints = () => {
        return <h4><MDBBadge color="primary" className="ml-2"
                             data-testid={"point-list-num-points"}>{this.props.points.length}</MDBBadge> {this.props.t("points")}
        </h4>
    }

    render() {

        if (this.state.visible) {
            openPointListAnalytics()
        }

        const points = this.state.searchedPoints.length > 0 ? this.state.searchedPoints : this.props.points
        const pointControls = points.map((point: EstuvePoint) => this.pointControl(point, this.props.goToPoint))
        return (
            <Control position="topleft">
                <div className={"point-list-map-control"} ref={this.refContainer}>
                    <div className={"point-list-toggle"}>
                        <button data-testid={"toggle-point-control"} className={"point-list-toggle-icon leaflet-bar"}
                                onClick={() => this.toggleVisible()}>
                            <i className="fas fa-map-marker " aria-hidden="true"/>
                        </button>
                    </div>
                    {this.state.visible &&
                    <div className={"point-list leaflet-bar"} data-testid={"point-list"}>
                        <this.NumberPoints/>
                        <this.SearchBar/>
                        <div className="container">
                            {pointControls}
                        </div>
                    </div>
                    }
                </div>
            </Control>
        );
    }
}