import React from 'react';
import {useCreatePoint} from '../../hooks/MapDetailsHook';
import {Controller, useForm} from 'react-hook-form'
import {EstuveMap} from "../../types/EstuveMap";
import {EstuvePoint} from "../../types/EstuvePoint";
import {MDBAlert} from "mdbreact";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import "./AddNewPointForm.css"
import {useTranslation} from "react-i18next";


class NewPointFormProps {
    map: EstuveMap
    lat: number
    lng: number
    hide: Function

    constructor(map: EstuveMap, hide: Function, lat: number, lng: number) {
        this.map = map
        this.hide = hide
        this.lat = lat
        this.lng = lng
    }
}

export const NewPointForm = (props: NewPointFormProps) => {
    const {register, handleSubmit, control, errors} = useForm({
        defaultValues: {
            latitude: props.lat,
            longitude: props.lng,
            description: "",
            date: ""
        }
    });
    const {createPoint} = useCreatePoint(props.map);
    const {t} = useTranslation("mapDetails")

    const onSubmit = (data: any) => {
        const newPoint = new EstuvePoint(parseFloat(data.latitude), parseFloat(data.longitude))
        newPoint.description = data.description
        if (data.date !== "")
            newPoint.date = moment(data.date).toDate()
        createPoint(newPoint)
        props.hide()
    };

    return <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{t("create point")}</strong>
        </h5>
        <div className="card-body px-lg-5 pt-0">
            <form data-testid="create-point-form" className={"md-form"} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder={t("latitude")} name="latitude" className={"form-control"}
                       ref={register({required: true})} readOnly disabled/>
                {errors.latitude &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='latitude-error'>{t("required")}</span></MDBAlert>}
                <input type="text" placeholder={t("longitude")} name="longitude" className={"form-control"}
                       ref={register({required: true})} readOnly disabled/>
                {errors.longitude &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='longitude-error'>{t("required")}</span></MDBAlert>}
                <textarea name="description" className={"form-control md-textarea"}
                          placeholder={t("description")} ref={register({required: true})}/>
                {errors.description &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='description-error'>{t("required")}</span></MDBAlert>}
                <Controller
                    dateFormat={"yyyy/MM/dd"}
                    isClearable
                    as={ReactDatePicker}
                    control={control}
                    valueName="selected"
                    onChange={([selected]) => selected}
                    name="date"
                    className={"input form-control"}
                    placeholderText={t("select date")}
                    defaultValue={""}
                    autoComplete="off"
                />
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-block btm-sm my-4 waves-effect z-depth-0" type={"button"}
                                onClick={() => props.hide()} data-testid="add-point-cancel-btn">{t("cancel")}
                        </button>
                    </div>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-block btm-sm my-4 waves-effect z-depth-0"
                                data-testid={"add-point-btn"}
                                type="submit">
                            {t("create")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}