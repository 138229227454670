export class EstuveMap {
    id: string
    name: string
    description: string
    numberOfPoints: number

    constructor(name: string, description: string) {
        this.name = name
        this.description = description
        this.id = ""
        this.numberOfPoints = 0
    }

    static buildWithId(name: string, description: string, id: string): EstuveMap {
        const map = new EstuveMap(name, description)
        map.id = id
        return map
    }
}

export class CreateMapRequest {
    name: string
    description: string

    constructor(name: string, description: string) {
        this.name = name
        this.description = description
    }
}