import {State} from "../types/State";
import {Action} from "../actions/Action";
import {ApiErrorAction} from "../actions/api/ApiActions";
import {apiReducer} from "./api/reducer";
import {MapAction} from "../actions/maps/MapActions";
import {mapReducer} from "./maps/reducer";
import {PointAction} from "../actions/points/PointActions";
import {pointReducer} from "./points/reducer";
import {authReducer} from "./auth/reducer";
import {SetUsernameAction} from "../actions/auth/AuthActions";

export const reducer = (state: State, action: Action): State => {

    if (action instanceof ApiErrorAction) {
        return apiReducer(state, action)
    } else if (action instanceof MapAction) {
        return mapReducer(state, action)
    } else if (action instanceof PointAction) {
        return pointReducer(state, action)
    } else if (action instanceof SetUsernameAction) {
        return authReducer(state, action)
    } else {
        throw new Error('Unexpected action');
    }
}