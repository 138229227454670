import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {forgotPassword, resetPasswordAfterForgot} from "../../auth/AmplifyAuth";
import {MDBAlert} from "mdbreact";
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../i18n";
import {forgotPasswordAnalytics, resetPasswordSuccessfulAnalytics} from "../../analytics/ga";

class ForgotPasswordProps {
    emailSent?: boolean = false
    email?: string = ""
}

export const ForgotPassword = (props: ForgotPasswordProps) => {

    const {register, handleSubmit, errors, setError} = useForm({
        defaultValues: {
            email: "",
            code: "",
            psw: "",
            invalidForgot: false,
            resetPassword: false
        }
    });
    const [sentEmail, setSentEmail] = useState(props.emailSent ? props.emailSent : false)
    const [resetSuccessful, setResetSuccessful] = useState(false)
    const [email, setEmail] = useState(props.email ? props.email : "")
    const {t} = useTranslation("auth");

    const handleForgotPassword = (data: any) => {
        if (!sentEmail) {
            setEmail(data.email)
            forgotPassword(data.email)
                .then(() => setSentEmail(true))
                .catch(() => setError("invalidForgot", "invalidForgot"))
        } else {
            resetPasswordAfterForgot(email, data.code, data.psw)
                .then(() => {
                        setResetSuccessful(true)
                        resetPasswordSuccessfulAnalytics()
                    }
                )
                .catch(() => setError("resetPassword", "resetPassword"))
        }
    }

    const getFormByEmailSent = () => {
        let content

        if (!sentEmail) {
            content = <div>
                <p>{t("password reset code")}</p>
                <input data-testid="forgot-email-input" type="text" placeholder={t("email")}
                       name="email" className="form-control"
                       ref={register({required: true})}/>
                {errors.email &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='email-required-error'>{t("required")}</span></MDBAlert>}
            </div>
        } else {
            content = <div>
                <p>{t("enter password reset code")}</p>
                <input data-testid="forgot-code-input" type="text" placeholder={t("verification code")}
                       name="code" className="form-control" ref={register({required: true})}/>
                {errors.code &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='code-required-error'>{t("required")}</span></MDBAlert>}
                <input data-testid="login-password-input" type="password" placeholder={t("new password")}
                       name="psw" className="form-control" ref={register({required: true})}/>
                {errors.psw &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='psw-required-error'>{t("required")}</span></MDBAlert>}
            </div>
        }
        return content
    }

    forgotPasswordAnalytics()

    return <div className={"fullscreen-with-color"}>
        <div className="centered-card card">
            <h5 className="card-header info-color white-text text-center py-4">
                <strong>{t("forgot password")}</strong>
            </h5>
            <div className="card-body px-lg-5 pt-0">
                <form data-testid="forgot-password-form" className={"md-form"}
                      onSubmit={handleSubmit(handleForgotPassword)}>
                    {getFormByEmailSent()}
                    <button className="btn btn-info btn-block my-4 waves-effect z-depth-0" data-testid="forgot-submit"
                            type="submit">{t("send")}
                    </button>
                    {errors.invalidForgot &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='forgot-invalid-error'>{t("error send forgot code")}</span></MDBAlert>}
                    {errors.resetPassword &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='reset-password-error'>{t("error reset password")}</span></MDBAlert>}
                    {resetSuccessful &&
                    <MDBAlert color="success" dismiss>
                        <span data-testid='reset-ok'>{t("reset ok")} <strong>
                            <a href={`/${getLanguage()}/login`}>{t("here")}</a></strong></span>
                    </MDBAlert>
                    }
                </form>
            </div>
        </div>
    </div>
}