import React from "react";
import {LayersControl, TileLayer} from "react-leaflet";

const {BaseLayer} = LayersControl

const NormalTile = () => {
    return <TileLayer
        maxZoom={19}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
}

const TopographicTile = () => {
    return <TileLayer
        maxZoom={17}
        attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors,
         <a href="http://viewfinderpanoramas.org">SRTM</a>| Map style: &copy; <a href="https://opentopomap.org">
         OpenTopoMap</a>(<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
    />
}

export const MapLayers = () => {
    return <LayersControl position={"topleft"}>
        <BaseLayer checked name={"Normal"}>
            <NormalTile/>
        </BaseLayer>
        <BaseLayer name={"Topographic"}>
            <TopographicTile/>
        </BaseLayer>
    </LayersControl>
}