import {API} from "aws-amplify";
import {getCurrentSession} from "../auth/AmplifyAuth";
import {authTokenHeader} from "./authHeader";
import {FeedbackMessage} from "../types/FeedbackMessage";

export const sendFeedbackMessage = (feedbackMsg: FeedbackMessage): Promise<Boolean> => {
    return getCurrentSession()
        .then((tokens) => {
            return API.post("estuve", "/feedback",
                {body: feedbackMsg, headers: authTokenHeader(tokens)})
                .then(() => true)
        })
}