import React from 'react';
import {NewPointForm} from "./AddNewPointForm";
import {UpdatePointForm} from "./PointForm";
import {DeletePointForm} from "./DeletePointForm";
import Modal from "react-bootstrap/Modal";

class AddPointProps {
    data: any
    isShowing: boolean
    toggle: Function

    constructor(isShowing: boolean, toggle: Function, data: any) {
        this.data = data
        this.isShowing = isShowing
        this.toggle = toggle
    }
}

class PointModalProps {
    data: any
    isShowing: boolean
    toggle: Function

    constructor(data: any, isShowing: boolean, toggle: Function) {
        this.data = data
        this.isShowing = isShowing
        this.toggle = toggle
    }
}

export const AddPointModal = (props: AddPointProps) => {
    if (props.data) {
        const {map, lat, lng} = props.data
        return <Modal show={props.isShowing} onHide={() => props.toggle()} centered>
            <Modal.Body>
                <NewPointForm map={map} lat={lat} lng={lng} hide={props.toggle}/>
            </Modal.Body>
        </Modal>
    }
    return null
}

export const EditPointModal = (props: PointModalProps) => {
    if (props.data) {
        const map = props.data.map
        const point = props.data.point
        return <Modal show={props.isShowing} onHide={() => props.toggle()} centered>
            <Modal.Body>
                <UpdatePointForm map={map} point={point} hide={props.toggle}/>
            </Modal.Body>
        </Modal>
    }
    return null
}

export const DeletePointModal = (props: PointModalProps) => {
    if (props.data) {
        const map = props.data.map
        const point = props.data.point
        return <Modal show={props.isShowing} onHide={() => props.toggle()} centered>
            <Modal.Body>
                <DeletePointForm map={map} point={point} hide={props.toggle}/>
            </Modal.Body>
        </Modal>
    }
    return null
}