import React from "react";
import L, {DragEndEvent} from "leaflet";
import {Marker} from "react-leaflet";

class TemporalMarkerProps {
    temporalPoint: TemporalPoint | null
    setTemporalPoint: ((tempPoint: TemporalPoint) => void)
    openConfirmAddPoint: (() => void)

    constructor(temporalPoint: TemporalPoint, setTemporalPoint: (tempPoint: TemporalPoint) => void, openConfirmAddPoint: () => void) {
        this.temporalPoint = temporalPoint;
        this.setTemporalPoint = setTemporalPoint;
        this.openConfirmAddPoint = openConfirmAddPoint;
    }
}

export class TemporalPoint {
    lat: number
    lng: number

    constructor(lat: number, lng: number) {
        this.lat = lat
        this.lng = lng
    }
}

export const TemporalMarker = (props: TemporalMarkerProps) => {
    if (props.temporalPoint) {
        const temporalPoint = props.temporalPoint
        const temporalIcon = new L.Icon({
            iconUrl: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
            shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
            className: "temporal-marker"
        });
        return <Marker icon={temporalIcon}
                       position={[temporalPoint.lat, temporalPoint.lng]} draggable
                       onDragEnd={(dragEvent: DragEndEvent) => props.setTemporalPoint(new TemporalPoint(dragEvent.target._latlng.lat, dragEvent.target._latlng.lng))}
                       onClick={() => props.openConfirmAddPoint()}/>
    }
    return null
}