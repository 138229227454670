import React, {useState} from 'react';
import {NavBar} from "../navbar/NavBar";
import {changePassword, deleteUser, logout, updateUsername} from "../../auth/AmplifyAuth";
import "./Profile.css"
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';
import {MDBAlert} from "mdbreact";
import {useForm} from "react-hook-form";
import {useAppContext} from "../../hooks/ContextHook";
import {SetUsernameAction} from "../../actions/auth/AuthActions";
import {renderProfileAnalytics} from "../../analytics/ga";

const ChangePasswordForm = (props: any) => {
    const [changed, setChanged] = useState<Boolean>(false)
    const {register, handleSubmit, errors, setError} = useForm();

    const onSubmit = (data: any) => {
        const oldPassword = data.current_password
        const newPassword = data.new_password
        changePassword(oldPassword, newPassword)
            .then(() => setChanged(true))
            .catch(() => setError("changing", "changing"))
    };

    return <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{props.t("change password")}</strong>
        </h5>
        <div className="card-body px-lg-5 pt-0">
            <form data-testid="change-password-form" className={"md-form"} onSubmit={handleSubmit(onSubmit)}>
                <input type="password" placeholder={props.t("current password")} name="current_password"
                       data-testid={"current-password-input"}
                       className={"form-control"}
                       ref={register({required: true})}/>
                {errors.current_password &&
                <MDBAlert color="danger" dismiss><span>{props.t("required")}</span></MDBAlert>}
                <input type="password" placeholder={props.t("new password")} name="new_password"
                       data-testid={"new-password-input"}
                       className={"form-control"}
                       ref={register({required: true})}/>
                {errors.new_password &&
                <MDBAlert color="danger" dismiss><span>{props.t("required")}</span></MDBAlert>}

                <div className={"row"}>
                    <div className={"col-sm-6"}>
                    </div>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-block my-4 waves-effect z-depth-0"
                                data-testid={"change-password-btn"}
                                type="submit">
                            {props.t("change")}
                        </button>
                    </div>
                </div>
                {errors.changing &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='password-changed-error'>{props.t("change password error")}</span></MDBAlert>}
                {changed &&
                <MDBAlert color="success" dismiss>
                    <span data-testid='password-changed'>{props.t("password changed")}</span>
                </MDBAlert>
                }
            </form>
        </div>
    </div>
}

const ChangeUsernameForm = (props: any) => {
    const {state, dispatch} = useAppContext()
    const {register, handleSubmit, errors, setError, setValue} = useForm({
        defaultValues : {
            username : state.username,
            changing: false
        }
    });

    setValue("username", state.username)
    const [changed, setChanged] = useState<Boolean>(false)

    const onSubmit = (data: any) => {
        const newUsername = data.username
        updateUsername(newUsername)
            .then(() => {
                setChanged(true)
                dispatch(new SetUsernameAction(newUsername))
            })
            .catch(() => setError("changing", "changing"))
    };

    return <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{props.t("update username")}</strong>
        </h5>
        <div className="card-body px-lg-5 pt-0">
            <form data-testid="change-username-form" className={"md-form"} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder={props.t("username")} name="username"
                       data-testid={"username-input"}
                       className={"form-control"}
                       ref={register({required: true})}/>
                {errors.username &&
                <MDBAlert color="danger" dismiss><span>{props.t("required")}</span></MDBAlert>}
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                    </div>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-block my-4 waves-effect z-depth-0"
                                data-testid={"change-username-btn"}
                                type="submit">
                            {props.t("change")}
                        </button>
                    </div>
                </div>
                {errors.changing &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='username-changed-error'>{props.t("change username error")}</span></MDBAlert>}
                {changed &&
                <MDBAlert color="success" dismiss>
                    <span data-testid='username-changed'>{props.t("username changed")}</span>
                </MDBAlert>
                }
            </form>
        </div>
    </div>
}
const DeleteUser = (props: any) => {
    const [error, setError] = useState<Boolean>(false)
    const callback = (err: Error | undefined, data: string | undefined): void => {
        if (err) {
            setError(true)
            return
        }
        logoutAndRedirect(props.history)
    }

    return <div className={"delete-user-form"}>
        <button className="btn btn-danger btn-block my-4 waves-effect z-depth-0" type={"button"}
                onClick={() => deleteUser(callback)} data-testid="delete-user-btn">{props.t("delete user")}
        </button>
        {error && <MDBAlert color="danger" dismiss><span
            data-testid='delete-user-error'>{props.t("cannot delete user")}</span></MDBAlert>}
    </div>
}
const logoutAndRedirect = (history: any) => {
    logout().then(() => history.push("/"))
}
export const Profile = () => {
    const history = useHistory()
    const {t} = useTranslation("profile")
    return <div className={"profile-wrapper"}>
        <ChangeUsernameForm t={t}/>
        <ChangePasswordForm t={t}/>
        <DeleteUser t={t} history={history}/>
    </div>
}

export const ProfilePage = () => {
    renderProfileAnalytics()
    return <>
        <NavBar/>
        <Profile/>
    </>
}