import React, {Fragment} from "react";
import {EstuvePoint} from "../../types/EstuvePoint";
import {EstuveMap} from "../../types/EstuveMap";
import {Marker, Popup} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

class MarkerPointProps {
    point: EstuvePoint | undefined
    map: EstuveMap | undefined
    openEditModal: Function = () => {
    }
    openDeleteModal: Function = () => {
    }
}

class MarkerListProps {
    points: Array<EstuvePoint> = [];
    map: EstuveMap | undefined
    openEditModal: Function = () => {
    }
    openDeleteModal: Function = () => {
    }
    useMarkerCluster: boolean = true
}

const PointMakerPopup = (props: MarkerPointProps) => {
    const point = props.point
    const map = props.map
    if (point && map) {
        return <div>
            <div data-testid={"point-popup"} className={"point-popup"}>
                <div>{point.description}</div>
                {point.date && <div>{EstuvePoint.displayDate(point)}</div>}
                <button className={"btn btn-info btn-sm"} data-testid={"btn-start-editing-point"}
                        onClick={() => props.openEditModal(point)}>
                    Edit
                </button>
                <button className={"btn btn-danger btn-sm"} data-testid={"btn-start-deleting-point"}
                        onClick={() => props.openDeleteModal(point)}>
                    Delete
                </button>
            </div>
        </div>
    }
    return null
}

const PointMarker = (props: MarkerPointProps) => {
    const point = props.point
    const map = props.map
    if (point && map) {
        return <Marker position={[point.latitude, point.longitude]} key={point.id}>
            <Popup className={`popup-${point?.latitude},${point?.longitude}`}>
                <PointMakerPopup point={point} map={map} openEditModal={props.openEditModal}
                                 openDeleteModal={props.openDeleteModal}/>
            </Popup>
        </Marker>
    }
    return null
}

export const PointListMarkers = (props: MarkerListProps) => {
    const markers = props.points.map(point => <PointMarker key={point.id} point={point} map={props.map}
                                                           openDeleteModal={props.openDeleteModal}
                                                           openEditModal={props.openEditModal}/>)

    const MarkersWithOptionalCluster = (props: any) => {
        if (props.useMarkerCluster) {
            return <MarkerClusterGroup>
                {props.markers}
            </MarkerClusterGroup>
        } else {
            return props.markers
        }
    }

    return <Fragment>
        <MarkersWithOptionalCluster markers={markers} useMarkerCluster={props.useMarkerCluster}/>
    </Fragment>
}