import React from 'react';

import {useUpdateMap} from '../../hooks/MapListHook'
import {useForm} from 'react-hook-form'
import {EstuveMap} from "../../types/EstuveMap";
import {MDBAlert} from "mdbreact";
import {useTranslation} from "react-i18next";

export class EditMapFormProps {
    map: EstuveMap
    hide: Function

    constructor(map: EstuveMap, hide: Function) {
        this.map = map
        this.hide = hide
    }
}

export const EditMapForm = (props: EditMapFormProps) => {

    const {register, handleSubmit, errors} = useForm({
        defaultValues: {
            name: props.map.name,
            description: props.map.description
        },
    });
    const {updateMap} = useUpdateMap();
    const onSubmit = (data: any) => {
        props.map.name = data.name
        props.map.description = data.description
        updateMap(props.map)
        props.hide()
    };
    const {t} = useTranslation("maps")

    return <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{t("modify map")}</strong>
        </h5>
        <div className="card-body px-lg-5 pt-0">
            <form data-testid="edit-map-form" className={"md-form"} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder={t("map name")} name="name"
                       className={"form-control"}
                       ref={register({required: true})}/>
                {errors.name && <MDBAlert color="danger" dismiss><span
                    data-testid='name-error'>{t("required")}</span></MDBAlert>}
                <textarea name="description" placeholder={t("map description")}
                          className={"md-textarea form-control"}
                          ref={register({required: true})}/>
                {errors.description &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='description-error'>{t("required")}</span></MDBAlert>}
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-block my-4 waves-effect z-depth-0"
                                data-test-id={"edit-cancel-btn"}
                                type={"button"}
                                onClick={() => props.hide()} data-testid="edit-map-cancel-btn">{t("cancel")}
                        </button>
                    </div>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-block my-4 waves-effect z-depth-0"
                                data-testid="edit-map-btn"
                                type="submit">
                            {t("save")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

}