import {Action} from "../Action";
import {SET_API_ERROR} from "../../events/ApiEvents";

export class ApiErrorAction extends Action {
    errorKey: string

    constructor(errorKey: string) {
        super(SET_API_ERROR)
        this.errorKey = errorKey
    }
}