import React, {useState} from 'react';
import {useGetPointsInMap} from '../../hooks/MapDetailsHook';
import {useModal} from '../../hooks/ModalHook'
import {useGetSelectedMap} from '../../hooks/MapListHook';
import {EstuvePoint} from "../../types/EstuvePoint";
import {EstuveMap} from "../../types/EstuveMap";
import {Map} from "react-leaflet"
import "./MapDetails.css"
import {LatLng, LatLngBoundsExpression} from "leaflet";
import {NavBar} from "../navbar/NavBar";
import {AddPointModal, DeletePointModal, EditPointModal} from "./MapDetailsModals";
import {PointListMarkers} from "./PointListMarkers";
import {TemporalMarker, TemporalPoint} from "./TemporalMarker";
import {MapLayers} from "./MapLayers";
import {SearchBar} from "./MapSearchBar";
import {ALL_WORLD_BOUNDS, getBoundsFromPoints} from "./BoundCalculator";
import {animateScroll as scroll} from 'react-scroll'
import {PointListMapControl} from "./PointListControl";
import {useTranslation} from "react-i18next";
import {ApiErrors} from "../errors/ApiErrors";
import {useAppContext} from "../../hooks/ContextHook";
import {FitBoundsButton} from "./FitBoundsButton";


require('react-leaflet-markercluster/dist/styles.min.css'); // inside .js file

export class MapDetailsProps {
    mapId: string
    searchBar?: boolean
    markerCluster?: boolean

    constructor(mapId: string) {
        this.mapId = mapId
    }
}

const InternalMap = (props: MapDetailsProps) => {
    const {state} = useAppContext();
    const map = useGetSelectedMap(props.mapId)
    const points = useGetPointsInMap(map)
    const {modalData: addPointModalData, toggle: addModalToggle, setDataAndShow: addPointSetDataAndShow} = useModal()
    const {modalData: editPointModalData, toggle: editModalToggle, setDataAndShow: editPointSetDataAndShow} = useModal()
    const {modalData: deletePointModalData, toggle: deleteModalToggle, setDataAndShow: deletePointSetDataAndShow} = useModal()
    const [pointPendingCreation, setPointPendingCreation] = useState<TemporalPoint | null>(null)
    const [goTo, setGoTo] = useState<LatLng | undefined>(undefined)
    const [zoom, setZoom] = useState<number | undefined>(undefined)
    const [bounds, setBounds] = useState<LatLngBoundsExpression | null>(null)
    const {t} = useTranslation("mapDetails")

    const usingSearchBar = props.searchBar !== undefined ? props.searchBar : true
    const usingMarkerCluster = props.markerCluster !== undefined ? props.markerCluster : true

    if (bounds === null && points.length !== 0) {
        setBounds(getBoundsFromPoints(points))
    }

    const openAddPointForm = (map: EstuveMap, lat: number, lng: number) => {
        addPointSetDataAndShow({map: map, lat: lat, lng: lng})
    }


    const setAndOpenEditModal = (pointToEdit: EstuvePoint) => {
        editPointSetDataAndShow({
            map: map,
            point: pointToEdit
        })
    }

    const setAndOpenDeleteModal = (pointToDelete: EstuvePoint) => {
        deletePointSetDataAndShow({
            map: map,
            point: pointToDelete
        })
    }

    const toggleTemporalMarker = (e: any) => {
        if (pointPendingCreation === null) {
            setPointPendingCreation(new TemporalPoint(e.latlng.lat, e.latlng.lng))
        } else {
            setPointPendingCreation(null)
        }
    }

    const stopAddingNewPoint = () => {
        addModalToggle()
        setPointPendingCreation(null)
        scroll.scrollToTop();
    }

    const noError = state.error === ""
    const className = noError ? "primary-color" : ""
    let mapRef: any = null

    return <div id={"map-wrapper"} className={className}>
        <div id={"point-container"} data-testid={"points-container"}>
            {!noError && <ApiErrors error={state.error}/>}
            <AddPointModal isShowing={addPointModalData.isShowing} data={addPointModalData.data}
                           toggle={stopAddingNewPoint}/>
            <EditPointModal isShowing={editPointModalData.isShowing} data={editPointModalData.data}
                            toggle={editModalToggle}/>
            <DeletePointModal isShowing={deletePointModalData.isShowing} data={deletePointModalData.data}
                              toggle={deleteModalToggle}/>
            {noError &&
            <Map id={"map"} bounds={bounds === null ? ALL_WORLD_BOUNDS : bounds}
                 center={goTo} zoom={zoom}
                 doubleClickZoom={false}
                 onDblClick={(e: any) => toggleTemporalMarker(e)}
                 ref={(map) => {mapRef = map}}
            >
                <MapLayers/>
                <SearchBar usingSearchBar={usingSearchBar}/>
                <PointListMarkers points={points} map={map} openEditModal={setAndOpenEditModal}
                                  openDeleteModal={setAndOpenDeleteModal} useMarkerCluster={usingMarkerCluster}/>
                <TemporalMarker temporalPoint={pointPendingCreation}
                                setTemporalPoint={(temporalPoint: TemporalPoint) => setPointPendingCreation(temporalPoint)}
                                openConfirmAddPoint={() => pointPendingCreation ?
                                    openAddPointForm(map, pointPendingCreation.lat, pointPendingCreation.lng) : {}}/>
                <FitBoundsButton fitBounds={() => mapRef.leafletElement.flyToBounds(bounds)}/>
                <PointListMapControl points={points}
                                     goToPoint={(point: EstuvePoint) => {
                                         setGoTo(new LatLng(point.latitude, point.longitude))
                                         setZoom(20)
                                     }}
                                     t={t}

                />

            </Map>
            }
        </div>
    </div>
}

export const MapDetails = (props: MapDetailsProps) => {

    const mapId = props.mapId

    return <div>
        <NavBar/>
        <InternalMap mapId={mapId} searchBar={props.searchBar} markerCluster={props.markerCluster}/>
    </div>

}