import {useState} from "react";

class ModalData {
    isShowing: boolean = false
    data: any
}

export const useModal = () => {
    const [modalData, setModalData] = useState(new ModalData());

    const toggle = () => {
        const newModalData = {...modalData}
        newModalData.isShowing = !modalData.isShowing
        setModalData(newModalData)
    }

    const setDataAndShow = (data: any) => {
        const newModalData = {...modalData}
        newModalData.data = data
        newModalData.isShowing = true
        setModalData(newModalData)
    }

    const show = () => {
        const newModalData = {...modalData}
        newModalData.isShowing = true
        setModalData(newModalData)
    }

    return {
        modalData,
        toggle,
        setDataAndShow,
        show
    }
}