import React from 'react';
import {MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle} from "mdbreact";
import {ToggleEnum} from "./NavBar";
import {getUsername, logout} from "../../auth/AmplifyAuth";
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../i18n";
import {useAppContext} from "../../hooks/ContextHook";
import {SetUsernameAction} from "../../actions/auth/AuthActions";

class UserMenuProps {
    toggleStatus: ToggleEnum

    constructor(toggleStatus: ToggleEnum) {
        this.toggleStatus = toggleStatus;
    }
}

class UserDropdownProps {
    userName: string
    history: any
    translate: Function


    constructor(userName: string, history: any, translate: Function) {
        this.userName = userName;
        this.history = history;
        this.translate = translate;
    }
}

const logoutAndRedirect = (history: any) => {
    logout().then(() => history.push("/"))
}

export const UserDropdown = (props: UserDropdownProps) => {
    return <MDBDropdown className={"user-toggler"}>
        <MDBDropdownToggle nav>
            <span data-testid="user-avatar" className="btn-floating btn-sm btn-info">{props.userName[0]}</span>
        </MDBDropdownToggle>
        <MDBDropdownMenu right={true} basic>
            <MDBDropdownItem className={"user-name"}>
                {props.userName}
            </MDBDropdownItem>
            <MDBDropdownItem>
                <ProfileLink toggled={false} translate={props.translate}/>
            </MDBDropdownItem>
            <MDBDropdownItem onClick={() => logoutAndRedirect(props.history)}>
                <LogoutLink toggled={false} history={props.history} translate={props.translate}/>
            </MDBDropdownItem>
        </MDBDropdownMenu>
    </MDBDropdown>
}

export const ProfileLink = (props: any) => {
    let className = props.toggled ? "white-text" : ""
    return <a className={className} href={`/${getLanguage()}/profile`}>{props.translate("profile")}</a>
}

export const LogoutLink = (props: any) => {
    let className = props.toggled ? "white-text" : ""
    return <a data-testid="logout-btn" href="/" className={className}
              onClick={() => logoutAndRedirect(props.history)}>{props.translate("logout")}</a>
}


export const UserMenu = (props: UserMenuProps) => {
    const {t} = useTranslation("navbar")

    const {state, dispatch} = useAppContext();
    const username = state.username
    if (username === "") {
        getUsername().then((username: string) => dispatch(new SetUsernameAction(username)))
    }

    const history = useHistory()
    if (props.toggleStatus === ToggleEnum.opened) {
        return <>
            <li><ProfileLink toggled={true} translate={t}/></li>
            <li><LogoutLink toggled={true} translate={t} history={history}/></li>
        </>
    } else {
        return UserDropdown({
            userName: username,
            history: history,
            translate: t
        })
    }
}