import {Action} from "../Action";
import {SET_USERNAME} from "../../events/AuthEvents";

export class SetUsernameAction extends Action {
    username: string

    constructor(username: string) {
        super(SET_USERNAME);
        this.username = username;
    }
}