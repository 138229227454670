import React, {useState} from 'react';
import {UserMenu} from "./UserMenu";
import "./Navbar.css"
import {MDBCollapse, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBNavItem} from "mdbreact";
import {CreateMapNavButton} from "./CreateMapNavButton";
import {useAppContext} from "../../hooks/ContextHook";
import {Help} from "./Help";
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../i18n";

export enum ToggleEnum {
    initial,
    opened,
    closed
}

const toggle = (toggleStatus: ToggleEnum): ToggleEnum => {
    if (toggleStatus === ToggleEnum.initial) {
        return ToggleEnum.opened
    }
    if (toggleStatus === ToggleEnum.opened) {
        return ToggleEnum.closed
    }
    return ToggleEnum.opened
}

const MapName = (props: any) => {
    if (props.mapName) {
        return <MDBNavItem>
            <span data-testid="map-name-nav" className={"nav-content"}>{props.mapName}</span>
        </MDBNavItem>
    }
    return null
}

const MapLink = () => {
    const {t} = useTranslation("navbar")
    return <MDBNavItem>
        <a data-testid="go-to-maps-btn" className={"nav-content"} href={`/${getLanguage()}/maps`}>{t("your maps")}</a>
    </MDBNavItem>
}

const AboutLink = () => {
    const {t} = useTranslation("main")
    return <MDBNavItem>
        <a href={`/${getLanguage()}/about`} className={"nav-content nav-link"}>{t("who and why")}</a>
    </MDBNavItem>
}
const ContactLink = () => {
    const {t} = useTranslation("navbar")
    return <MDBNavItem>
        <a href={`/${getLanguage()}/contact`} className={"nav-content nav-link"}>{t("contact")}</a>
    </MDBNavItem>
}

export const NavBar = () => {
    const {state} = useAppContext();
    const [toggleStatus, setToogleStatus] = useState<ToggleEnum>(ToggleEnum.initial)
    const isToggled = () => toggleStatus === ToggleEnum.opened
    const mapSelected = state.selectedMap !== undefined
    let selectedMapName = undefined
    if (mapSelected) {
        selectedMapName = state.selectedMap.name
    }

    return <MDBNavbar className={"estuve-navbar navbar-dark primary-color"} fixed={"top"} dark expand="md">
        <MDBNavbarBrand>
            <strong className="white-text">Estuve.eu</strong>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={() => setToogleStatus(toggle(toggleStatus))}/>
        <MDBCollapse isOpen={isToggled()} navbar>
            <MDBNavbarNav left>
                <MapLink/>
                <CreateMapNavButton toggleStatus={toggleStatus} isMapSelected={mapSelected}/>
                <MapName mapName={selectedMapName}/>
            </MDBNavbarNav>
            <MDBNavbarNav right>
                <AboutLink/>
                <ContactLink/>
                <Help isMapSelected={mapSelected} toggleStatus={toggleStatus}/>
                <MDBNavItem className={"nav-user-menu"}>
                    <UserMenu toggleStatus={toggleStatus}/>
                </MDBNavItem>
            </MDBNavbarNav>
        </MDBCollapse>
    </MDBNavbar>
}