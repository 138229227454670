import ReactGA from 'react-ga';

export const initGa = () => {
    ReactGA.initialize('UA-167422214-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}
export const renderMainPageAnalytics = () => {
    ReactGA.event({
        category: 'Navigation',
        action: 'Render main page'
    });
}
export const renderLoginAnalytics = () => {
    ReactGA.event({
        category: 'Navigation',
        action: 'Render login page'
    });
}
export const renderRegisterAnalytics = () => {
    ReactGA.event({
        category: 'Navigation',
        action: 'Render register page'
    });
}
export const loginFailAnalytics = () => {
    ReactGA.event({
        category: 'Auth',
        action: 'Failed login'
    });
}
export const loginSuccessfulAnalytics = () => {
    ReactGA.event({
        category: 'Auth',
        action: 'Login OK'
    });
}
export const registerStartAnalytics = () => {
    ReactGA.event({
        category: 'Auth',
        action: 'Register started'
    });
}
export const registerFailedAnalytics = () => {
    ReactGA.event({
        category: 'Auth',
        action: 'Register failed'
    });
}
export const registerSuccessfulAnalytics = () => {
    ReactGA.event({
        category: 'Auth',
        action: 'Register OK'
    });
}
export const forgotPasswordAnalytics = () => {
    ReactGA.event({
        category: 'Auth',
        action: 'Forgot password start'
    });
}
export const resetPasswordSuccessfulAnalytics = () => {
    ReactGA.event({
        category: 'Auth',
        action: 'Reset password OK'
    });
}
export const renderWhoAnalytics = () => {
    ReactGA.event({
        category: 'Navigation',
        action: 'Render about page'
    });
}
export const sendFeedbackMessageAnalytics = () => {
    ReactGA.event({
        category: 'Contact',
        action: 'Sent feedback message'
    });
}
export const renderProfileAnalytics = () => {
    ReactGA.event({
        category: 'Navigation',
        action: 'Render profile page'
    });
}
export const renderMapAnalytics = () => {
    ReactGA.event({
        category: 'Map',
        action: 'Render map'
    });
}
export const createMapAnalytics = () => {
    ReactGA.event({
        category: 'Map',
        action: 'Create map'
    });
}
export const editMapAnalytics = () => {
    ReactGA.event({
        category: 'Map',
        action: 'Edit map'
    });
}
export const deleteMapAnalytics = () => {
    ReactGA.event({
        category: 'Map',
        action: 'Delete map'
    });
}
export const createPointAnalytics = () => {
    ReactGA.event({
        category: 'Point',
        action: 'Create point'
    });
}
export const editPointAnalytics = () => {
    ReactGA.event({
        category: 'Point',
        action: 'Edit point'
    });
}
export const deletePointAnalytics = () => {
    ReactGA.event({
        category: 'Point',
        action: 'Delete point'
    });
}
export const centerMapAnalytics = () => {
    ReactGA.event({
        category: 'Map tools',
        action: 'Click in center map'
    });
}
export const openPointListAnalytics = () => {
    ReactGA.event({
        category: 'Map tools',
        action: 'Open point list'
    });
}