const config = require("./config.json")

class Configuration {
    apiUrl: string
    userPoolId: string
    appClientId: string
    region: string
    publicUrl: string
    useGa: boolean

    constructor(apiUrl: string, userPoolId: string, appClientId: string, region: string, publicUrl: string, useGa: boolean) {
        this.apiUrl = apiUrl;
        this.userPoolId = userPoolId;
        this.appClientId = appClientId;
        this.region = region
        this.publicUrl = publicUrl
        this.useGa = useGa
    }
}

export const Config = () => new Configuration(config.apiUrl, config.userPoolId, config.appClientId, config.region, config.publicUrl, config.useGa)