import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {confirmUser, resendConfirmUser} from "../../auth/AmplifyAuth";
import {MDBAlert} from "mdbreact";
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../i18n";
import {registerSuccessfulAnalytics} from "../../analytics/ga";

export const ConfirmUser = () => {
    const defaultEmail = "";
    const {register, handleSubmit, errors, setError} = useForm();
    const [resendSuccess, setResendSuccess] = useState(false)
    const [confirmSuccess, setConfirmSuccess] = useState(false)
    const [email, setEmail] = useState(defaultEmail)
    const {t} = useTranslation("auth");

    const sendConfirmUser = (data: any) => {
        confirmUser(data.email, data.code)
            .then(() => {
                setConfirmSuccess(true)
                registerSuccessfulAnalytics()
            }).catch((err) => setError("invalidCode", "invalidCode"))
    }

    const resendVerificationCode = () => {
        if (email === defaultEmail) {
            setError("email", "email")
            return
        }
        resendConfirmUser(email)
            .then(() => setResendSuccess(true))
            .catch((err) => setError("retryCode", "retryCode"))
    }


    return <div className={"fullscreen-with-color"}>
        <div className="centered-card card">
            <h5 className="card-header info-color white-text text-center py-4">
                <strong>{t("confirm")}</strong>
            </h5>
            <div className="card-body px-lg-5 pt-0">

                <p>{t("use code")}</p>

                <form data-testid="confirm-user-form" className={"md-form"} onSubmit={handleSubmit(sendConfirmUser)}>
                    <input data-testid="confirm-email-input" type="text"
                           placeholder={t("email")}
                           className="form-control"
                           name="email"
                           autoComplete="off"
                           ref={register({required: true})}
                           onChange={(event) => setEmail(event.target.value)}/>
                    {errors.email &&
                    <MDBAlert color="danger" dismiss>
                        <span
                            data-testid='email-required-error'>{t("required")}</span>
                    </MDBAlert>
                    }
                    <input data-testid="confirm-code-input" type="text"
                           placeholder={t("verification code")} name="code"
                           className="form-control"
                           autoComplete="off"
                           ref={register({required: true})}/>
                    {errors.code && <MDBAlert color="danger" dismiss>
                        <span data-testid='code-required-error'>{t("required")}</span></MDBAlert>}
                    {errors.invalidCode &&
                    <MDBAlert color="danger" dismiss>
                        <span data-testid='code-invalid-error'>{t("incorrect code")}</span></MDBAlert>}

                    <button data-testid="confirm-submit" type="submit"
                            className={"btn btn-info btn-block my-4 waves-effect z-depth-0"}>{t("confirm button")}
                    </button>

                    <div>
                        {/* eslint-disable-next-line*/}
                        <a data-testid="resend-code-btn" onClick={() => {
                            resendVerificationCode()
                        }}>{t("send code again")}</a>
                    </div>

                    {errors.retryCode &&
                    <MDBAlert color="danger" dismiss>
                        <span data-testid='retry-code-error'>{t("could not resend code")}</span>
                    </MDBAlert>}
                    {resendSuccess &&
                    <MDBAlert color="success" dismiss>
                        <span data-testid='resend-ok'>{t("code sent")}</span>
                    </MDBAlert>
                    }
                    {confirmSuccess &&
                    <MDBAlert color="success" dismiss>
                        <span data-testid='confirm-ok'>{t("confirmed")} <strong>
                            <a href={`/${getLanguage()}/login`}>{t("here")}</a></strong></span>
                    </MDBAlert>
                    }
                </form>
            </div>

        </div>
    </div>


}