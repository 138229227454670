import React from 'react';
import "./Loading.css"

export const Loading = () => {
    return <div id={"loading-wrapper"}>

        <div className={"loader"}>
            <div className={"spinner"}/>
            <p>Loading ...</p>
        </div>
    </div>
}