import React from 'react';
import {useCreateMap} from '../../hooks/MapListHook'
import {useForm} from 'react-hook-form'
import {EstuveMap} from "../../types/EstuveMap";
import {MDBAlert} from "mdbreact";
import {useTranslation} from "react-i18next";

class NewMapFormProps {
    hide: Function

    constructor(hide: Function) {
        this.hide = hide
    }
}

export const NewMapForm = (props: NewMapFormProps) => {

    const {register, handleSubmit, errors} = useForm();
    const {createMap} = useCreateMap();
    const {t} = useTranslation("maps")
    const onSubmit = (data: any) => {
        createMap(new EstuveMap(data.name, data.description))
        props.hide()
    };

    return <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{t("create map")}</strong>
        </h5>
        <div className="card-body px-lg-5 pt-0">
            <form data-testid="new-map-form" className={"md-form"} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder={t("map name")} name="name"
                       className={"form-control"}
                       ref={register({required: true})}/>
                {errors.name && <MDBAlert color="danger" dismiss><span
                    data-testid='name-error'>{t("required")}</span></MDBAlert>}
                <textarea name="description" placeholder={t("map description")}
                          className={"md-textarea form-control"}
                          ref={register({required: true})}/>
                {errors.description &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='description-error'>{t("required")}</span></MDBAlert>}
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-block my-4 waves-effect z-depth-0" type={"button"}
                                onClick={() => props.hide()} data-testid="new-map-cancel-btn">{t("cancel")}
                        </button>
                    </div>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-block my-4 waves-effect z-depth-0"
                                data-testid={"new-map-btn"}
                                type="submit">
                            {t("create")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

}