import React from 'react';

import {useDeleteMap} from '../../hooks/MapListHook'
import {EstuveMap} from "../../types/EstuveMap";
import {useTranslation} from "react-i18next";

export class DeleteMapFormProps {
    map: EstuveMap
    hide: Function

    constructor(map: EstuveMap, hide: Function) {
        this.map = map
        this.hide = hide
    }
}

export const DeleteMapForm = (props: DeleteMapFormProps) => {
    const deleteDoneCallback = () => props.hide()
    const {deleteMap} = useDeleteMap(deleteDoneCallback);
    const {t} = useTranslation("maps")

    return <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{t("delete map")}</strong>
        </h5>
        <div data-testid="delete-map-form" className="card-body px-lg-5 pt-0">
            <p>{t("map delete confirmation")} <b>{props.map.name}</b> ?</p>
            <div className={"row"}>
                <div className={"col-sm-6"}>
                    <button data-testid="delete-map-cancel-btn"
                            className={"btn btn-info btn-block my-4 waves-effect z-depth-0"}
                            onClick={() => props.hide()}>{t("cancel")}
                    </button>
                </div>
                <div className={"col-sm-6"}>
                    <button data-testid="delete-map-delete-btn"
                            className={"btn btn-danger btn-block my-4 waves-effect z-depth-0"}
                            onClick={() => deleteMap(props.map)}>{t("delete")}
                    </button>
                </div>
            </div>

        </div>
    </div>

}