import React from 'react';
import {useUpdatePoint} from '../../hooks/MapDetailsHook';
import {Controller, useForm} from 'react-hook-form'
import {EstuvePoint} from "../../types/EstuvePoint";
import {EstuveMap} from "../../types/EstuveMap";
import {MDBAlert} from "mdbreact";
import ReactDatePicker from "react-datepicker";
import moment from "moment"
import {useTranslation} from "react-i18next";


class UpdatePointFormProps {
    point: EstuvePoint
    map: EstuveMap
    hide: Function

    constructor(point: EstuvePoint, map: EstuveMap, hide: Function) {
        this.point = point
        this.hide = hide
        this.map = map
    }
}

export const UpdatePointForm = (props: UpdatePointFormProps) => {
    const pointDate = props.point.date ? moment(props.point.date).toDate() : undefined
    const {register, handleSubmit, errors, control} = useForm({
        defaultValues: {
            latitude: EstuvePoint.displayLatitude(props.point),
            longitude: EstuvePoint.displayLongitude(props.point),
            description: props.point.description,
            date: pointDate,
        }
    });

    const {updatePoint} = useUpdatePoint(props.map);
    const {t} = useTranslation("mapDetails")

    const onSubmit = (data: any) => {
        const newPoint = new EstuvePoint(parseFloat(data.latitude), parseFloat(data.longitude))
        newPoint.description = data.description
        if (data.date === null) {
            newPoint.date = undefined
        } else {
            newPoint.date = new Date(data.date)
        }
        newPoint.id = props.point.id
        updatePoint(newPoint)
        props.hide()
    };

    return <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{t("edit point")}</strong>
        </h5>
        <div className="card-body px-lg-5 pt-0">
            <form data-testid="edit-point-form" className={"md-form"} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder={t("latitude")} name="latitude" className={"form-control"} readOnly
                       ref={register({required: true})}/>
                {errors.latitude &&
                <MDBAlert color="danger" dismiss><span data-testid='latitude-error'>{t("required")}</span></MDBAlert>}
                <input type="text" placeholder={t("longitude")} name="longitude" className={"form-control"} readOnly
                       ref={register({required: true})}/>
                {errors.longitude &&
                <MDBAlert color="danger" dismiss><span data-testid='longitude-error'>{t("required")}</span></MDBAlert>}
                <textarea name="description" placeholder={t("description")} className={"form-control md-textarea"}
                          ref={register({required: true})}/>
                {errors.description && <MDBAlert color="danger" dismiss><span
                    data-testid='description-error'>{t("required")}</span></MDBAlert>}

                <Controller
                    dateFormat={"yyyy/MM/dd"}
                    isClearable
                    as={ReactDatePicker}
                    control={control}
                    valueName="selected"
                    onChange={([selected]) => selected}
                    name="date"
                    className={"input form-control"}
                    placeholderText={t("select date")}
                    defaultValue={""}
                    autoComplete="off"
                />

                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-sm my-4 waves-effect z-depth-0" type={"button"}
                                onClick={() => props.hide()} data-testid="update-point-cancel-btn">{t("cancel")}
                        </button>
                    </div>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-sm my-4 waves-effect z-depth-0"
                                data-testid={"update-point-btn"}
                                type="submit">
                            {t("update")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}