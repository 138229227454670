import React from 'react';
import {errors as mapErrors} from "../../api/MapApi"
import {errors as mapDetails} from "../../api/MapDetailsApi"
import {MDBAlert} from "mdbreact";
import {useTranslation} from "react-i18next";

class ApiErrorProps {
    error: string = ""
}

const apiErrorKeyDefinition = new Map([
    [mapErrors.CANNOT_READ_MAPS, "error read maps"],
    [mapErrors.CANNOT_READ_MAP, "error read map"],
    [mapErrors.CANNOT_CREATE_MAP, "error create map"],
    [mapErrors.CANNOT_UPDATE_MAP, "error update map"],
    [mapErrors.CANNOT_DELETE_MAP, "error delete map"],
    [mapDetails.CANNOT_READ_POINTS, "error read points"],
    [mapDetails.CANNOT_CREATE_POINT, "error create point"],
    [mapDetails.CANNOT_UPDATE_POINT, "error update point"],
    [mapDetails.CANNOT_DELETE_POINT, "error delete point"],
]);

export const ApiErrors = (props: ApiErrorProps) => {
    const {t} = useTranslation("errors")
    if (props.error === "") return null
    const errorKey = apiErrorKeyDefinition.get(props.error)
    if (errorKey) {
        return <MDBAlert color="danger" dismiss><span data-testid='api-error'>{t(errorKey)}</span></MDBAlert>
    }
    return <MDBAlert color="danger" dismiss><span data-testid='api-error'>{t("default-api-error")}</span></MDBAlert>

}

