import React, {useState} from "react";
import {useAppContext} from "./ContextHook";
import {createMap, deleteMap, errors, getMapById, getMapList, updateMap} from '../api/MapApi'
import {
    CreateMapAction,
    DeleteMapAction,
    ReadMapAction,
    ReadMapsAction,
    UpdateMapAction
} from "../actions/maps/MapActions";
import {ApiErrorAction} from "../actions/api/ApiActions";
import {EstuveMap} from "../types/EstuveMap";


export const useGetMaps = (): EstuveMap[] => {
    const {state, dispatch} = useAppContext();
    React.useEffect(() => {
        getMapList()
            .then(maps => {
                dispatch(new ReadMapsAction(maps))
            })
            .catch(err => {
                dispatch(new ApiErrorAction(errors.CANNOT_READ_MAPS))
            })
        // eslint-disable-next-line
    }, []);
    // eslint-disable-next-line
    const {maps} = state;
    return maps;
}

export const useGetSelectedMap = (mapId: string): EstuveMap => {
    const {state, dispatch} = useAppContext();
    React.useEffect(() => {
        getMapById(mapId,)
            .then(selectedMap => dispatch(new ReadMapAction(selectedMap)))
            .catch(err => dispatch(new ApiErrorAction(errors.CANNOT_READ_MAP)))
        // eslint-disable-next-line
    }, []);
    const {selectedMap} = state;
    return selectedMap;
}

export const useCreateMap = () => {
    const {dispatch} = useAppContext();
    const [map, setMap] = useState<EstuveMap | undefined>(undefined)

    React.useEffect(() => {
        const createMapAndDispatch = (map: EstuveMap) => {
            createMap(map,)
                .then(mapCreated => dispatch(new CreateMapAction(mapCreated)))
                .catch(err => dispatch(new ApiErrorAction(errors.CANNOT_CREATE_MAP)))
        }
        if (map) {
            createMapAndDispatch(map);
        }
        // eslint-disable-next-line
    }, [map]);

    return {createMap: (map: EstuveMap) => setMap(map)}
}

export const useUpdateMap = () => {
    const {dispatch} = useAppContext();
    const [map, setMap] = useState<EstuveMap | undefined>(undefined)

    React.useEffect(() => {
        const updateMapAndDispatch = (map: EstuveMap) => {
            updateMap(map,)
                .then(mapUpdated => {
                    dispatch(new UpdateMapAction(mapUpdated))
                })
                .catch(err => {
                    dispatch(new ApiErrorAction(errors.CANNOT_UPDATE_MAP))
                })
        }
        if (map) {
            updateMapAndDispatch(map)
        }
        // eslint-disable-next-line
    }, [map]);

    return {updateMap: (map: EstuveMap) => setMap(map)}
}

export const useDeleteMap = (doneCallback?: Function) => {
    const {dispatch} = useAppContext();
    const [map, setMap] = useState<EstuveMap | undefined>(undefined)

    React.useEffect(() => {
        const deleteMapAndDispatch = (map: EstuveMap) => {
            deleteMap(map)
                .then(() => dispatch(new DeleteMapAction(map)))
                .catch(() => dispatch(new ApiErrorAction(errors.CANNOT_DELETE_MAP)))
        }

        if (map) {
            deleteMapAndDispatch(map);
            if (doneCallback) {
                doneCallback()
            }
        }
        // eslint-disable-next-line
    }, [map]);

    return {
        deleteMap: (map: EstuveMap) => setMap(map)
    }
}