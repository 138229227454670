import React, {useState} from 'react';
import {FeedbackMessage} from "../../types/FeedbackMessage";
import {NavBar} from "../navbar/NavBar";
import "./Feedback.css"
import {MDBAlert} from "mdbreact";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {sendFeedbackMessage} from "../../api/FeedbackApi";
import {sendFeedbackMessageAnalytics} from "../../analytics/ga";

export const FeedbackForm = () => {

    const [sent, setSent] = useState<Boolean>(false)
    const {register, handleSubmit, errors, setError} = useForm();
    const {t} = useTranslation("feedback")
    const onSubmit = (data: any) => {
        const msg = new FeedbackMessage(data.subject, data.message)
        sendFeedbackMessage(msg)
            .then(() => {
                setSent(true)
                sendFeedbackMessageAnalytics()
            })
            .catch((err) => {
                setError("sending_error", "sending_error")
            })
    };

    return <div className="card feedback-wrapper">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{t("send us a message")}</strong>
        </h5>
        <div className="card-body px-lg-5 pt-0">
            <p className={"feedback-txt"}>{t("help us")}</p>
            <form data-testid="feedback-form" className={"md-form"} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder={t("subject")} name="subject" className={"form-control"}
                       ref={register({required: true})}/>
                {errors.subject && <MDBAlert color="danger" dismiss><span
                    data-testid='subject-error'>{t("required")}</span></MDBAlert>}
                <textarea name="message" placeholder={t("message")} className={"md-textarea form-control"}
                          rows={10}
                          ref={register({required: true})}/>
                {errors.message &&
                <MDBAlert color="danger" dismiss><span data-testid='message-error'>{t("required")}</span></MDBAlert>}
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                    </div>
                    <div className={"col-sm-6"}>
                        <button className="btn btn-info btn-block my-4 waves-effect z-depth-0"
                                data-testid={"send-feedback-btn"}
                                type="submit">
                            {t("send")}
                        </button>
                    </div>
                </div>
                {errors.sending_error &&
                <MDBAlert color="danger" dismiss><span
                    data-testid='sending-error'>{t("sending-error")}</span></MDBAlert>}
                {sent &&
                <MDBAlert color="success" dismiss>
                    <span data-testid='feedback-sent'>{t("feedback-sent")}</span>
                </MDBAlert>
                }
            </form>
        </div>
    </div>
}

export const FeedbackPage = () => {
    return <>
        <NavBar/>
        <FeedbackForm/>
    </>
}