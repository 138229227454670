import React from 'react';
import {MDBNavItem} from "mdbreact";
import {useModal} from "../../hooks/ModalHook";
import {ToggleEnum} from "./NavBar";
import "./Help.css"
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";

class HelpProps {
    isMapSelected: boolean
    toggleStatus: ToggleEnum

    constructor(isMapSelected: boolean, toggleStatus: ToggleEnum) {
        this.isMapSelected = isMapSelected;
        this.toggleStatus = toggleStatus;
    }
}

class HelpModalProps {
    modalContent: any
    isShowing: boolean
    toggle: Function


    constructor(modalContent: any, isShowing: boolean, toggle: Function) {
        this.modalContent = modalContent;
        this.isShowing = isShowing;
        this.toggle = toggle;
    }
}

const MapDetailsHelp = () => {
    const {t} = useTranslation("navbar")
    return <div data-testid={"map-details-help"} className={"help-content"}>
        <p>{t("mapDetailsHelp1")}</p>
        <p>{t("mapDetailsHelp2")}</p>
        <p>{t("mapDetailsHelp3")}</p>
    </div>
}

const MapListHelp = () => {
    const {t} = useTranslation("navbar")
    return <div data-testid={"map-list-help"} className={"help-content"}>
        <p>{t("mapListHelp1")}</p>
        <p>{t("mapListHelp2")}</p>
    </div>
}

const HelpModal = (props: HelpModalProps) => {
    return <Modal show={props.isShowing} onHide={() => props.toggle()} centered>
        <Modal.Body>
            <HelpModalContents isShowing={props.isShowing} toggle={props.toggle} modalContent={props.modalContent}/>
        </Modal.Body>
    </Modal>
}

const HelpModalContents = (props: HelpModalProps) => {
    const {t} = useTranslation("navbar")
    return <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{t("help")}</strong>
        </h5>
        <div className="card-body px-lg-5 pt-0">
            {props.modalContent}
            <button className="btn btn-info btn-block my-4 waves-effect z-depth-0" type={"button"}
                    onClick={() => props.toggle()} data-testid="help-hide-btn">
                OK
            </button>
        </div>
    </div>
}

const HelpButton = (props: any) => {
    return <div className={"nav-link"}>
            <span data-testid="help-btn" className={" btn-floating btn-sm btn-info"}
                  style={{cursor: "pointer"}}
                  onClick={() => props.openHelpModal()}
            >?</span>
    </div>
}

export const HelpLink = (props: any) => {
    return <span data-testid="help-link" className={"white-text"}
                 onClick={() => props.openHelpModal()}>{props.t("help")}</span>
}

export const Help = (props: HelpProps) => {
    const {t} = useTranslation("navbar")
    const {modalData, toggle, setDataAndShow} = useModal()
    const modalContent = props.isMapSelected ? <MapDetailsHelp/> : <MapListHelp/>
    const openHelpModal = () => setDataAndShow({})
    const isToggled = props.toggleStatus === ToggleEnum.opened
    return <MDBNavItem>
        {isToggled && <HelpLink openHelpModal={openHelpModal} t={t}/>}
        {!isToggled && <HelpButton openHelpModal={openHelpModal}/>}
        <HelpModal modalContent={modalContent} isShowing={modalData.isShowing} toggle={toggle}/>
    </MDBNavItem>
}