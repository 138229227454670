import {useTranslation} from "react-i18next";
import {MDBNavbar, MDBNavbarBrand} from "mdbreact";
import {Link} from "react-scroll";
import React from "react";
import {getLanguage} from "../../i18n";

class MainNavBarProps {
    isInMainPage = true
}

export const MainNavBar = (props: MainNavBarProps) => {
    const {t} = useTranslation("main");
    return <MDBNavbar color={"primary-color"} fixed={"top"} dark expand="md">
        <MDBNavbarBrand>
            <a href={`/${getLanguage()}/`} className={"white-text"}>Estuve.eu</a>
        </MDBNavbarBrand>
        {props.isInMainPage &&
        <div className={"ml-auto"}>
            <Link className={"main-navbar-link"} activeClass="navbar-link-active" to="main" spy={true}
                  smooth={true}>{t("main")}</Link>
            <Link className={"main-navbar-link"} activeClass="navbar-link-active" to="features" spy={true}
                  smooth={true}>{t("features")}</Link>
            <Link className={"main-navbar-link"} activeClass="navbar-link-active" to="why" spy={true}
                  smooth={true}>{t("who and why")}</Link>
        </div>
        }
    </MDBNavbar>
}