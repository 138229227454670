import React from 'react';
import {useForm} from 'react-hook-form'
import {registerUser} from "../../auth/AmplifyAuth";
import {useHistory} from 'react-router-dom';
import {MDBAlert} from "mdbreact";
import {useTranslation} from "react-i18next";
import {registerFailedAnalytics, registerStartAnalytics, renderRegisterAnalytics} from "../../analytics/ga";

export const Register = () => {
    const {t} = useTranslation("auth");

    const history = useHistory()
    const {register, handleSubmit, errors, setError} = useForm();
    const onSubmit = (data: any) => {

        registerStartAnalytics()

        const isInvalidParamPassword = (err: Error) => err.name === "InvalidParameterException" && err.message.includes("password")
        const isPasswordError = (err: Error) => err.name === "InvalidPasswordException"
        const isUserExistsError = (err: Error) => err.name === "UsernameExistsException"
        if (data.psw === data.confirmpsw) {
            registerUser(data.uname, data.email, data.psw)
                .then(() => history.push("/confirm"))
                .catch((err) => {
                    registerFailedAnalytics()
                    if (isPasswordError(err) || isInvalidParamPassword(err)) {
                        setError("incorrectPwdFormat", "incorrectPwdFormat")
                    } else if (isUserExistsError(err)) {
                        setError("emailAlreadyExists", "emailAlreadyExists")
                    } else {
                        setError("genericError", "genericError")
                    }
                })
        } else {
            setError("noConfirmation", "noConfirmation")
        }
    };
    renderRegisterAnalytics()
    return <div className={"fullscreen-with-color"}>
        <div className="centered-card card">
            <h5 className="card-header info-color white-text text-center py-4">
                <strong>{t("register")}</strong>
            </h5>
            <div className="card-body px-lg-5 pt-0">
                <form data-testid="register-form" className={"md-form"} onSubmit={handleSubmit(onSubmit)}>
                    <input type="email" placeholder={t("email")} name="email"
                           className={"form-control"}
                           ref={register({required: true})}
                           data-testid="register-email-input"/>
                    {errors.email &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='email-error'>{t("required")}</span></MDBAlert>}
                    {errors.emailAlreadyExists &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='error-email-exists'>{t("already registered")}</span></MDBAlert>}
                    <input type="text" placeholder={t("username")} name="uname"
                           ref={register({required: true})}
                           className={"form-control"}
                           data-testid="register-name-input"/>
                    {errors.uname &&
                    <MDBAlert color="danger" dismiss><span data-testid='uname-error'>{t("required")}</span></MDBAlert>}
                    <input type="password" placeholder={t("password")} name="psw"
                           className={"form-control"}
                           ref={register({required: true})}
                           data-testid="register-password-input"/>
                    {errors.psw &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='psw-error'>{t("required")}</span></MDBAlert>}
                    {errors.incorrectPwdFormat &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='psw-error-format'>{t("error password format")}</span></MDBAlert>}
                    <input type="password" placeholder={t("confirm password")} name="confirmpsw"
                           className={"form-control"}
                           ref={register({required: true})}
                           data-testid="register-confirm-password-input"/>
                    {errors.confirmpsw &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='psw-confirm-error'>{t("required")}</span></MDBAlert>}
                    {errors.noConfirmation &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='psw-confirm-not-equal'>{t("confirm fail")}</span></MDBAlert>}

                    <button className="btn btn-info btn-block my-4 waves-effect z-depth-0"
                            data-testid="register-submit"
                            type="submit">{t("register")}
                    </button>

                    {errors.genericError &&
                    <MDBAlert color="danger" dismiss><span
                        data-testid='generic-error'>{t("register error")}</span></MDBAlert>}
                </form>
            </div>
        </div>
    </div>
}