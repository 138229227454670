import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {Auth} from "aws-amplify";
import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import {getLanguage} from "../i18n";

export const isProtectedRoute = (path: string) => {
    return path.includes("maps")
}

export const isLandingPage = (path: string) => path === "/"

export const IsUserLoggedIn = () => {
    let location = useLocation();
    const history = useHistory()

    useEffect(() => {
        checkUserIsLoggedOnLoad();
        // eslint-disable-next-line
    }, []);

    function checkUserIsLoggedOnLoad() {

        if (isLandingPage(location.pathname)) {
            Auth.currentSession()
                .then(() => history.push("/maps"))
                .catch(() => {
                })
        }

        if (isProtectedRoute(location.pathname)) {
            Auth.currentSession()
                .then(() => handleUserIsLoggedIn())
                .catch((err) => handleUserNeedsLogin(err))
        }
    }

    const handleUserIsLoggedIn = () => {
        history.push(location.pathname)
    }

    const handleUserNeedsLogin = (err: any) => {
        console.log(err)
        history.push("/login")
    }

}

export const logIn = (email: string, password: string) => {
    return Auth.signIn(email, password)
        .then((user: CognitoUser) => user.getSignInUserSession()?.getIdToken().payload.name)
}

export const confirmUser = (email: string, code: string) => {
    return Auth.confirmSignUp(email, code)
}

export const resendConfirmUser = (email: string) => {
    return Auth.resendSignUp(email, clientMetaData())
}

export const registerUser = (username: string, email: string, password: string) => {
    return Auth.signUp({
        username: email,
        password: password,
        attributes: {
            email: email,
            name: username
        },
        clientMetadata: clientMetaData()
    })
}

export const forgotPassword = (email: string) => {
    return Auth.forgotPassword(email, clientMetaData())
}

export const resetPasswordAfterForgot = (email: string, verificationCode: string, newPassword: string) => {
    return Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
}

export const getCurrentSession = async () => {
    return await Auth.currentSession()
}

export const getUsername = async (): Promise<string> => {
    return await Auth.currentSession().then((session: CognitoUserSession) => session.getIdToken().payload.name)
}

export const logout = () => Auth.signOut()


export const updateUsername = (newUsername: string) => {
    const nameAttribute = {
        "Name": "name",
        "Value": newUsername
    }

    return Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => user.updateAttributes([nameAttribute], () => {
        }))
}

export const changePassword = (oldPassword: string, newPassword: string) => {
    return Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => Auth.changePassword(user, oldPassword, newPassword))
}

export const deleteUser = (callback: (err: Error | undefined, data: string | undefined) => void) => {
    return Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => user.deleteUser((err, data) => {
            callback(err, data)
        }))
}

const clientMetaData = () => {
    return {
        language: getLanguage()
    }
}