import React from 'react';
import {useDeletePoint} from '../../hooks/MapDetailsHook';
import {EstuvePoint} from "../../types/EstuvePoint";
import {EstuveMap} from "../../types/EstuveMap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

class DeletePointFormProps {
    point: EstuvePoint
    map: EstuveMap
    hide: Function

    constructor(point: EstuvePoint, map: EstuveMap, hide: Function) {
        this.point = point
        this.hide = hide
        this.map = map
    }
}

export const DeletePointForm = (props: DeletePointFormProps) => {

    const deleteDoneCallback = () => {
        props.hide()
    }

    const {deletePoint} = useDeletePoint(props.map, deleteDoneCallback);
    const {t} = useTranslation("mapDetails")

    const onDelete = () => {
        deletePoint(props.point)
        props.hide()
    }

    const {handleSubmit} = useForm();

    return <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
            <strong>{t("delete point")}</strong>
        </h5>
        <div className="card-body px-lg-5 pt-0">
            <form data-testid="delete-point-form" onSubmit={handleSubmit(onDelete)} className={"md-form"}>
                <div className={"text-center"} data-testid={"delete-point-block"}>
                    <p>{t("are you sure")}</p>
                    <p><b>{props.point.description}</b></p>
                    <p><b>{EstuvePoint.displayLatitude(props.point)},{EstuvePoint.displayLongitude(props.point)}</b></p>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <button type={"button"} className="btn btn-info btn-block btm-sm my-4 waves-effect z-depth-0"
                                data-testid="delete-point-cancel-btn" onClick={() => props.hide()}>{t("cancel")}
                        </button>
                    </div>
                    <div className={"col-sm-6"}>
                        <button data-testid="delete-point-delete-btn"
                                className="btn btn-danger btn-block btm-sm my-4 waves-effect z-depth-0">{t("delete")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}