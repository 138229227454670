import {CreatePointRequest, EstuvePoint} from "../types/EstuvePoint";
import {EstuveMap} from "../types/EstuveMap";
import {API} from "aws-amplify";
import {authTokenHeader} from "./authHeader";
import {getCurrentSession} from "../auth/AmplifyAuth";
import {createPointAnalytics, deletePointAnalytics, editPointAnalytics, renderMapAnalytics} from "../analytics/ga";

export const errors = {
    CANNOT_READ_POINTS: "CANNOT_READ_POINTS",
    CANNOT_CREATE_POINT: "CANNOT_CREATE_POINT",
    CANNOT_UPDATE_POINT: "CANNOT_UPDATE_POINT",
    CANNOT_DELETE_POINT: "CANNOT_DELETE_POINT",
}

export const getPointsInMap = (map: EstuveMap): Promise<EstuvePoint[]> => {
    renderMapAnalytics()
    return getCurrentSession()
        .then((tokens) => {
            return API.get("estuve", `/points/${map.id}`, {headers: authTokenHeader(tokens)})
        })
}

export const createPointInMap = (map: EstuveMap, point: EstuvePoint): Promise<EstuvePoint> => {
    const req = new CreatePointRequest(point.latitude, point.longitude, point.description, point.date)
    createPointAnalytics()
    return getCurrentSession()
        .then((tokens) => {
            return API.post("estuve", `/points/${map.id}`,
                {body: req, headers: authTokenHeader(tokens)})
        })
}

export const updatePoint = (map: EstuveMap, pointToUpdate: EstuvePoint): Promise<EstuvePoint> => {
    editPointAnalytics()
    return getCurrentSession()
        .then((tokens) => {
            return API.put("estuve", `/points/${map.id}/${pointToUpdate.id}`, {
                body: pointToUpdate, headers: authTokenHeader(tokens)
            })
        })
}

export const deletePoint = (map: EstuveMap, pointToDelete: EstuvePoint): Promise<Boolean> => {
    deletePointAnalytics()
    return getCurrentSession()
        .then((tokens) => {
            return API.del("estuve", `/points/${map.id}/${pointToDelete.id}`, {
                body: pointToDelete, headers: authTokenHeader(tokens)
            }).then(() => true)
        })
}