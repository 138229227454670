import Control from "react-leaflet-control";
import React from "react";
import {centerMapAnalytics} from "../../analytics/ga";

class FitBoundsButtonProps {
    fitBounds: Function


    constructor(fitBounds: Function) {
        this.fitBounds = fitBounds;
    }
}

export const FitBoundsButton = (props: FitBoundsButtonProps) => {
    return <Control position="topleft">
        <button data-testid={""} className={"point-list-toggle-icon leaflet-bar"}
                onClick={() => {
                    centerMapAnalytics()
                    props.fitBounds()
                }}>
            <i className="fas fa-globe"/>
        </button>
    </Control>
}